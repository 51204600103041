import styled from 'styled-components';
let Container = styled.div<{width:number ,height:number , background:string , color:string}>`
  position: absolute;
  z-index:2;
  left: 5px;
  width: 100%;
  height: 5px;
  text-align:right;
  color: ${props => props.color};
  .Collapsible{
    background: ${props => props.background};
    position: relative;
    float:right;
    top:10px;
    transition: width 0.3s ease;
    display: inline-block;
    text-align:left;
    .Collapsible__trigger{
      &.is-closed{
        width:12px;
        height:18px;
        position: relative;
        display: inline-block;
      }
      &.is-open{
        width:180px;
        position: relative;
        display: inline-block;
      }
    }


`
let DirContainer = styled.div`
  display: flex; /* Use flexbox to arrange children side by side */
  justify-content: end; /* Add space between the divs */
  flex-direction: column;
`

let AddButtonContainer = styled.div`
  display: flex; /* Use flexbox to arrange children side by side */
  justify-content: left; /* Add space between the divs */
  flex-direction: row;
`
let AddButton = styled.button<{background:string}>`
    width:60px;
    height:45px;
    display:flex;
    background:rgba(0,0,0,0);
    border:none;
    z-index:999;
    
`
let Img = styled.img`
    width:40px;
    height:30px
    left:0;
    top:0;
`
const FileUI = styled.input<{fontColor:string , backgroundColor:string , depth:number }> `
	width: 95%;
	padding: 0x 30px 30px 30pxpx;
  height:40px;
  border-radius: 2px;
  border-color: ${props => props.fontColor };
  background-color: rgba(0,0,0,0);
  color: ${props => props.fontColor };
  display: inline-block;
  font-size:13px;
  position: relative; //
  top:5px;
  left:${props => props.depth*10 }px;
  z-index:100;
  border-width:0px;
  border-bottom:solid;
  float:left;
  
` 
const Folder = styled.input<{fontColor:string , backgroundColor:string , depth:number}> `
	width: 95%;
	padding: 0x 30px 30px 30pxpx;
  height:40px;
	border-radius: 2px;
  border-color: ${props => props.fontColor };
  background-color: rgba(0,0,0,0);
  color: ${props => props.fontColor };
  display: inline-block;
  font-size:13px;
  position: relative; //
  top:5px;
  left:${props => props.depth*10 }px;
  z-index:100;
  border-width:0px;
  border-bottom:solid; 
  float:left;
` 
const AllFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
`
const FileContainer = styled.div`
  position: relative;
  width:100%
`
// Define the styled list component
const EditFileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 90px;
  font-size: 16px;
  color: #333;
  z-index:999;
  position:absolute;
  background-color:#bababa;
  right:30px;
`;

// Define the styled list item component
const EditFileItem = styled.li`
  padding: 10px;
  margin: 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: lightgray;
  cursor: pointer;
  height:6px;
  width:100%;
  &:hover {
    background-color: #f0f0f0;
  }
`;
const FileHoverButton = styled.div`
  position: absolute;
  z-index: 999;
  right: 20px;
  top: 7px;
`
export {Container , DirContainer , AddButtonContainer , AddButton , Img , FileUI , Folder , AllFilesContainer , FileContainer , EditFileList , EditFileItem , FileHoverButton};