
import katex from "katex";
import "katex/dist/katex.min.css";

import React , {useState , useRef, useEffect} from 'react';
import Editor from './Editor';


import { connect } from 'react-redux';
import { compose } from 'redux';
import { NotesToolBox } from './NoteToolBox';
import Quill from 'quill';
import { CollapsibleDirectory } from "./CollapsibleDirectory";
import styled from 'styled-components';
import { getNoteData } from "./CollapsibleDirHelpers";
import { updateStyles } from '../helpers';
import TreeView from "./TreeView";
import { noteUpdate } from "../../../../store/actions/noteActions";
import { saveStyle } from "../../../../store/actions/blockActions";
const Delta = Quill.import('delta');

let Container = styled.div<{width:number , height:number , background:string}>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background:${props => props.background};
    .controls {
        display: flex;
        border: 1px solid #ccc;
        border-top: 0;
        padding: 10px;
      }
      
      .controls-right {
        margin-left: auto;
      }
      
      .state {
        margin: 10px 0;
        font-family: monospace;
      }
      
      .state-title {
        color: #999;
        text-transform: uppercase;
      }
`
function getDefaultParams(state , blockId){
  let params = {habitEntrys: {} , bg:'rgba(255,255,255,1)' , textColor:'black' , habitColor:'rgb(120,50,60)' ,habitName: 'Enter Habit Name' }
  let layout = null;
  if(state.layout[0].layout){
    layout = state.layout[0].layout
  }
  else{
    layout = state.layout[0]
  }


  try{
      params.bg = layout[blockId].blockData.widgetStyle.bg;
    }
  catch{

  }
  try{
    params.habitColor = layout[blockId].blockData.widgetStyle.habitColor;
  }
  catch{
    
  }
  try{
    params.textColor = layout[blockId].blockData.widgetStyle.textColor
  }
  catch{

  }
  try{
    params.habitName = layout[blockId].blockData.habitName;
  }
  catch{

  }
  try{
    params.habitEntrys= layout[blockId].blockData.habitEntrys;
  }
  catch{

  }
  return params
}
const defaultMargin = { top: 10, left: 0, right: 0, bottom: 10 };

export const NotesBlock = (width:number,height:number,blockId:number, onDelete,saveStyle, state ,noteUpdate:any, ) =>  {
  let params = getDefaultParams(state, blockId);
  let [background , setBackground] = useState(params.bg);
  let [textColor, setTextColor] = React.useState(params.textColor);
  let [hovering, setHovering] = React.useState(false);
  let [changedProperty , setChangedProperty] = useState(false); 
  let [activeNoteIndex , setActiveNoteIndex] = useState(-1);
  let [editorContent , setEditorContent] = useState<any>('default content');
  let [treeView, setTreeView] = useState(true);
  console.log("SATE IN NOTE BLOCK IS: " , state)
  // Use a ref to access the quill instance directly
  const quillRef = useRef()

  const content = '<p>Hello World!</p>'


  function MouseOver(event) {
    setHovering(true);
    //console.log("NOTE HOVERING set to true" , hovering)
}
function MouseOut(event){
  //FUnction is being called when toolbox opens up even though it's a child of the 
  // containing div which has a mouse out event
  let colorPickerClassNames = ["react-colorful__pointer react-colorful__saturation-pointer","react-colorful__pointer react-colorful__hue-pointer","react-colorful__interactive"]
  if(event.target.id !== "NoteToolBox" && !colorPickerClassNames.includes(event.target.className)){
      console.log("out of vid cause: !",typeof event.target , event.target, event.target.id);
      setHovering(false);
      if(changedProperty){
        let updatedStyle = {bg: background, textColor: textColor}
        updateStyles(state.layout , state.auth , blockId , updatedStyle , saveStyle);//make new action here not noteupdate
        setChangedProperty(false);
      }
  }
}
  function stopPropagation(event: {target: any;stopPropagation: () => void; }){
    if(event.target.id !== "MoveBox"){
      event.stopPropagation();
    }
  };
  useEffect(() => {
    console.log("\n\nSTATE: " , state)
    console.log('tree blockId',state.layout[0].layout[blockId].blockData.fileTree , blockId)
    try{
      console.log('note data first try: ' , getNoteData(state.layout[0].layout[blockId].blockData.fileTree , activeNoteIndex))
      setEditorContent(getNoteData(state.layout[0].layout[blockId].blockData.fileTree , activeNoteIndex))
    }
    catch{
      setEditorContent(getNoteData(state.layout[0][blockId].blockData.fileTree , activeNoteIndex))
    }
  } 
  , [activeNoteIndex])
  //console.log("NOTE text color is " ,textColor)
  console.log("ACTIVE NOTE: " , activeNoteIndex , state);

  
  return (
    <>
    <Container background={background} height={height} width={width} onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut}>
      {hovering && <NotesToolBox activeNote={activeNoteIndex} toFileList={() => setActiveNoteIndex(-1)}  onChangedProperty={setChangedProperty} blockId={blockId} onDelete={onDelete}  onChangeBackground={setBackground} onChangeTextColor={setTextColor} ></NotesToolBox>}
      
      { activeNoteIndex === -1  ? <TreeView setActiveNote={(newNoteIndex) => setActiveNoteIndex(newNoteIndex)} state={state} 
                                      saveLayout={noteUpdate} blockId={blockId} textColor={textColor} background={background} width={180} height={height}></TreeView> 
                                     : <Editor height={height}  toolBarColor={textColor} state={state} noteUpdate={noteUpdate} blockId={blockId} activeNote={activeNoteIndex}/>}
    </Container>    
  </>
  )
}
const mapStateToProps = (state) => ({
    state: state,
  });
export default compose(connect(    mapStateToProps , {noteUpdate , saveStyle} ,null ))(NotesBlock);


