import axios from 'axios'
let base_url = process.env.NODE_ENV === 'development' ? 'https://localhost:80' :  process.env.REACT_APP_BASE_URL;

export const attachTokenToHeaders = (getState) => {
    const token = getState().auth.token;
  
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
  
    if (token) {
      config.headers['x-auth-token'] = token;
    }
  
    return config;
  };
export const saveImage = (id, link,blockId, history) => async (dispatch, getState) => {
    console.log("**********IN  save image" , link , blockId ,'*********');
    try{
        console.log('loading... ypdate image link' ,base_url , process.env.NODE_ENV)
        const options = attachTokenToHeaders(getState);
        const response = await axios.put(base_url + `/image/updateLink/${id}`, {'link':link , 'blockId':blockId}, options);
        console.log("RESPONSE save habit item " , response);
        dispatch({
          type: "imageLinkUpdate",
          payload: {link: link , blockId:blockId},
        });
    }
    catch{ 
        console.log("error in calesndar save action")
    }
  };  