
let initialState = {
        slackToken: localStorage.getItem('slackToken'),
        slackUid: localStorage.getItem('slackUid'),
        slackTeamId: localStorage.getItem('slackTeamId'),
    }

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'SLACK_LOGIN':
            console.log()
            localStorage.setItem('slackToken',payload.slackToken);
            localStorage.setItem('slackUid',payload.slackUid);
            localStorage.setItem('slackTeamId',payload.slackUid);
            return {...payload};
        default:
            return initialState
    }
}