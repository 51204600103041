
import { EmailData } from './EmailDataTypes';
import { Base64 } from "js-base64";
import parse from 'html-react-parser';
import React from 'react';
function sameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
}
export function getDisplayDate(emailDateStr:string){
    /*
    Given a date of a email, this function return what time should be displayed
    in the email preview e.g May 3rd or 11:22 or 2:33 

    emailDateStr: e.g '199700000' representing the UTC time the email was sent
    */

    //add timezone offset to the email
    const timezoneOffset = (new Date()).getTimezoneOffset();
    let emailSentInt = Number(emailDateStr) + timezoneOffset;
    let emailDate = new Date(emailSentInt);
    let now = new Date();
    if(sameDay(emailDate , now)){
        return emailDate.toLocaleTimeString('en-US', {hour12:true,hour:'numeric',minute:'numeric'});
    }
    else{
        return emailDate.toLocaleDateString('en-US');
    }
   
}
export function extractNameAndEmail(emailString: string): { name: string, email: string }{
    const leftBracketIndex = emailString.indexOf('<');
    const rightBracketIndex = emailString.indexOf('>');

    if (leftBracketIndex !== -1 && rightBracketIndex !== -1) {
        const name = emailString.substring(0, leftBracketIndex).trim();
        const email = emailString.substring(leftBracketIndex + 1, rightBracketIndex).trim();

        return { name, email };
    } else {
        return {name:emailString,email:emailString};
    }
}
export function getEmailData(email:any) : EmailData{
    /*
    Thread: a Gmail thread Class: https://developers.google.com/gmail/api/reference/rest/v1/users.threads

    subject === '' --> make it be this messages subject instead of the param subhect
    returns data about the most recent email in thread
    */
    const previewData:EmailData = {
        displayDate: "Error date not found",
        senderDisplay: "Error Sender Not Found",
        snippet: "Error Preview Not Found",
        rawThread:{},
        content:'',
        senderAdderess:'',
        subject:'',
        attachmentIds: [],
        messageId: email.id
    }
    //console.log('email: ' , email);
    for(let i = 0; i < email.payload.headers.length;i++){
        if(email.payload.headers[i].name === 'From'){
            const result = extractNameAndEmail(email.payload.headers[i].value);
            previewData.senderDisplay = result.name as string;
            previewData.senderAdderess = result.email as string;
        }
        if(email.payload.headers[i].name === 'Subject'){
            previewData.subject = email.payload.headers[i].value;
        }
    }
    for(let i = 0; i < email.payload.parts;i++){
        if(email.payload.parts[i].body.hasOwnProperty('attachmentId')){
            previewData.attachmentIds.push( [ email.payload.parts[i].body.attachmentId , email.payload.parts[i].body.mimeType])
        }
    }
    //hry all attachmentIds
    previewData.displayDate = getDisplayDate(email.internalDate);
    previewData.snippet = email.snippet;
    previewData.rawThread = email;
    return previewData;
}

export function getDefaultParams(state , blockId){
    let params = {bg:'rgba(255,255,255,.5)' , textColor:'black' }
    let layout = null;
    if(state.layout[0].layout){
        layout = state.layout[0].layout
      }
      else{
        layout = state.layout[0]
      }
    try{
      if(layout[blockId].blockData.widgetStyle.bg && layout[blockId].blockData.widgetStyle.textColor
       ){
       params.bg = layout[blockId].blockData.widgetStyle.bg;
       params.textColor = layout[blockId].blockData.widgetStyle.textColor;
       return params
      }
    }
    catch{
      return params;
    }
    return params
}


function getHtmlData(message){
    /*
        This function is used to get some html data from gmail messages 
        of mime type multipart/mixed which might have nested parts
        message: {body: {} , mimeType: {},  parts: {message}}
    */
   if(message.mimeType !== 'text/html'){
        if(message.parts == null){
            return null
        }
        for(let part of message.parts){
            let val = getHtmlData(part);
            if(val != null){
                return val;
            }
        }
    }
    else{
        return message.body.data;
    }
    return null;
}

export function getMessageContent(message: { payload: { mimeType: string; body: { data: string; }; parts: string | any[] | any; }; }){
    //switch payload/body/mimeType
    //      text/html --> get threads[i].payload.body --> base64 decode --> html string to display
    //      multipart/alternative --> check what payload.parts[i] is html --> base64 decode --> html string to display
    console.log("mtype" ,  message.payload.mimeType)
    if(message.payload.mimeType === "text/html"){
        var decodedHtml = Base64.decode(message.payload.body.data);
        return ( parse(decodedHtml) ) ;
    }
    else if(message.payload.mimeType === "multipart/alternative"){
        for(let i = 0; i < message.payload.parts.length;i++){
            console.log(i , message.payload.parts[i].mimeType);
            if(message.payload.parts[i].mimeType === "text/html"){
                var decodedHtml = Base64.decode(message.payload.parts[i].body.data);
                return <div> {parse(decodedHtml)} </div>;
            }
        }

        //if not html content is there try to display the text/plain
        for(let i = 0; i < message.payload.parts.length;i++){
            console.log(i , message.payload.parts[i].mimeType);
            if(message.payload.parts[i].mimeType === "text/plain"){
                var decodedStr = Base64.decode(message.payload.parts[i].body.data);
                return <div> {decodedStr}</div> ;
            }
        }
    }
    else if(message.payload.mimeType === "multipart/mixed"){
        console.log('in mixed xhecl' , message.payload);
        for(let i = 0; i <  message.payload.parts[0].parts.length; i++){
            let part = message.payload.parts[0].parts[i];

            if(part.mimeType === "text/html"){
                console.log('yay' , part.body.data)
                var decodedHtml = Base64.decode(part.body.data);
                return <div> {parse(decodedHtml)} </div>;
            }
            else{
                let html = getHtmlData(part);
                if(html !== null){
                    var decodedHtml = Base64.decode(html);
                    return <div> {parse(decodedHtml)} </div>;
                }
            }
        }
    }
    else if(message.payload.mimeType === "text/plain"){
        console.log('plain text' , message)
        return  <div> Base64.decode(message.payload.body.data) </div>;
    
    }
    console.log(message.payload.mimeType , message);
    return "________________________" + message.payload.mimeType;
}




export function getAttachmentIds(message){
    message = message.payload;
    let attachmentIds = [];
    for(let part of message.parts){
        if(part.body.hasOwnProperty("attachmentId")){
            attachmentIds.push(part.body.attachmentId)
        }
    }
    return attachmentIds;
}
export function getMimeTypes(message){
    let mimeTypes = [];
    for(let part of message.payload.parts){
        if(part.body.hasOwnProperty("attachmentId")){
            mimeTypes.push(part.mimeType)
        }
    }
    return mimeTypes;
}