import React from "react"
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { saveImage } from '../../../../store/actions/imageActions';

import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import listenForOutsideClick from "../../ToolBox/listen-for-outside-clicks";
let ToolBoxContainer = styled.div`position: absolute;
    pointer-events:auto;`
const ImageToolBox = (props: {blockId:number,onDelete(arg0: number): unknown,onChangeLink(arg0: string): unknown; }) => {
    let options =  ['Select','Delete', 'Upload Picture', 'Embed Link'];
    const [linkInput, setLinkInput] = React.useState('');
    const [showLinkInput, setShowLinkInput] = React.useState(false);
    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [showOptions , setShowOptions] = useState(false);
    const toggle = () => setShowOptions(false);
    useEffect(listenForOutsideClick(listening, setListening, menuRef, setShowOptions));
    function handleLinkInputChange(e) {
        setLinkInput(e.target.value);
    }
    let onEvent =  (e: { target: { value: any; }; }) => {
        let eventType = e.target.value;
        if(eventType === 'Delete'){
            props.onDelete(props.blockId);
        }
        else if(eventType === 'Upload Picture'){
            console.log('TO do upload picture');
        }
        else if(eventType === "Embed Link"){
            setShowLinkInput(true);
        }
    }
    function onOptionClick(){
        if(showOptions){
            setShowOptions(false);
        }
        else{
            setShowOptions(true);
        }
    }
    return (<div ref={menuRef} id={'imgToolBox'} className={showOptions ? "m-menu -active" : "m-menu "}>
            <div onClick={onOptionClick} id='OptionBox'><OptionBox/></div>
            { showOptions && <select className={"Option"} id='imageToolBox' onChange={onEvent}>
                {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
                ))}
            </select>
            } 
            {
                showLinkInput ? <input className={"Option"} id='imageLinkInput' onChange={handleLinkInputChange} defaultValue={""} type="text" placeholder="some text"     onKeyPress={async event => {
                    if (event.key === 'Enter') {
                      props.onChangeLink(linkInput);
                      setShowLinkInput(false);
                    }
                  }}/> : ''
            }
            <MoveBox/>
            </div>);
}



export const ImageBlock = (width:number,height:number,blockId:number, onDelete , blockData ,state:any, saveImage:any) => {
    let [hovering, setHovering] = useState(false);
    let [pictureLink , setPictureLink] = React.useState(blockData.link);
    /*document.addEventListener('contextmenu',(event) => {
        event.preventDefault();
        console.log(event.type);
      });*/
      function MouseOver(event) {
        setHovering(true);
      }
      function MouseOut(event){
        setHovering(false);
      }

    async function handleLinkChange(link:string){
        console.log('in link change...')
        setPictureLink(link);
        saveImage(state.auth.me.id  , link ,blockId, null);
        
    }
    function stopPropagation(event: {target: any;stopPropagation: () => void; }){
        if(event.target.id !== "MoveBox"){
          event.stopPropagation();
        }
      };
    console.log("IMG HOVERING", hovering)
    return<div onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut}>
                <ToolBoxContainer>
                    { hovering && <ImageToolBox  blockId={blockId} onDelete={onDelete} onChangeLink={(link: string) => { handleLinkChange(link); return; } } ></ImageToolBox>}
                </ToolBoxContainer>
                
                <img src={pictureLink} width={width} height={height}/>
                
            </div>

}
const mapStateToProps = (state) => ({
    auth: state.auth,
  });
export default compose(connect(    mapStateToProps , {saveImage} ,null ))(ImageBlock);
