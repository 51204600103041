

import React , {useState} from 'react';
import { Group } from '@visx/group';
import genBins, { Bin, Bins } from '@visx/mock-data/lib/generators/genBins';
import { scaleLinear } from '@visx/scale';
import { HeatmapCircle, HeatmapRect } from '@visx/heatmap';
import { getSeededRandom } from '@visx/mock-data';
import { saveLayout , loadLayouts } from '../../../../store/actions/userActions';
import { addHabitEntry,editHabitName } from '../../../../store/actions/HabitActions';
import { compose } from 'redux';
import { HoverOverRectPopUp , PlotContainer , DayLabel , HeatMapContainer , DayLabelContainer , MonthLabelContainer , HabitNameContainer,HabitNameInput } from './HeatMapTrackerStyles';
import { connect } from 'react-redux';
import { HeatMapToolBox } from './HeatMapToolBox';
import Color from 'color';
import { AddHabitPopUp } from './AddHabitPopUp';
import { generateLast50WeeksDates , generateCurrentWeekDates , getLast12Months } from './Helpers';
import { useDebouncedCallback } from "use-debounce";
import { updateStyles } from '../helpers';
import { saveStyle } from '../../../../store/actions/blockActions';
const cool1 = 'rgb(30,30,30)';
const cool2 = 'rgb(30,30,90)';
const seededRandom = getSeededRandom(0.41);
const datesList = generateLast50WeeksDates();
const currentWeekDates = generateCurrentWeekDates();



function genHabitData(habitEntrys){
  /*

  habitEntrys: 06-03-2024: true or  06-03-2024: 52.5
  Make empty bin of 50 x 7 + current week
  [
    {bin:0 , bins:[
       {bin:0 , count:0 , date: 06-03-2024}
       {bin:150 , count:0 , date: date: 06-02-2024}
       {bin:150 , count:0 , date: 06-03-2024}
    ] 
    } , 
  ]
  */
  if( habitEntrys === undefined){
    habitEntrys = {}
  }
  let habitData = []
  for(let i = 0;i<50;i++){
    let weekData = [];
    for(let j = 0;j<7;j++){
      let date_index = (i * 7) + j
      let date = datesList[date_index];
      if(!(date in habitEntrys)){
        weekData.push({date: date , count:0 , bin:150 * j})
      }
      else{
        weekData.push({date: date , count:habitEntrys[date] , bin:150 * j})
      }
    }
    habitData.push({ bin: i , bins: weekData} );
  }
  let currWeekBinData = [];
  for(let i = 0; i< currentWeekDates.length; i++){
    if(currentWeekDates[i] in habitEntrys){
      let userHabitEntry = habitEntrys[currentWeekDates[i]];
      if(userHabitEntry === true){
        userHabitEntry = 1;
      }
      else if(userHabitEntry === false){
        userHabitEntry = 0;
      }
      currWeekBinData.push({date: currentWeekDates[i] , count:userHabitEntry, bin:150 * i})
    }
    else{
      currWeekBinData.push({date: currentWeekDates[i] , count:0 , bin:150 * i})
    }
  }
  habitData.push({ bin: 51 , bins: currWeekBinData} );
  return habitData;
  const binData = genBins(
    /* length = */ 51,
    /* height = */ 7,
    /** binFunc */ (idx) => 150 * idx,
    /** countFunc */ (i, number) => 0,
  );

}

const binData = genBins(
  /* length = */ 51,
  /* height = */ 7,
  /** binFunc */ (idx) => 150 * idx,
  /** countFunc */ (i, number) => 1,
);
function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.max(...data.map(value));
}

function min<Datum>(data: Datum[], value: (d: Datum) => number): number {
  return Math.min(...data.map(value));
}

// accessors
const bins = (d: Bins) => d.bins;
const count = (d: Bin) => d.count;


const bucketSizeMax = max(binData, (d) => bins(d).length);

// scales
const xScale = scaleLinear<number>({
  domain: [0, binData.length],
});
const yScale = scaleLinear<number>({
  domain: [0, bucketSizeMax],
});



interface hoverOverDateType {
  x: number;
  y: number;
  date:string;
}

function getDefaultParams(state , blockId){
  let params = {habitEntrys: {} , bg:'rgba(255,255,255,1)' , textColor:'black' , habitColor:'rgb(120,50,60)' ,habitName: 'Enter Habit Name' }
  let layout = null;
  if(state.layout[0].layout){
    layout = state.layout[0].layout
  }
  else{
    layout = state.layout[0]
  }
  console.log("LAYOUT IS: in get default is " , layout , state)

  try{
      params.bg = layout[blockId].blockData.widgetStyle.bg;
    }
  catch{

  }
  try{
    params.habitColor = layout[blockId].blockData.widgetStyle.habitColor;
  }
  catch{
    
  }
  try{
    params.textColor = layout[blockId].blockData.widgetStyle.textColor
  }
  catch{

  }
  try{
    params.habitName = layout[blockId].blockData.habitName;
  }
  catch{

  }
  try{
    params.habitEntrys= layout[blockId].blockData.habitEntrys;
  }
  catch{

  }
  return params
}
const defaultMargin = { top: 10, left: 0, right: 0, bottom: 10 };

export const HeatMapTracker = (width:number,height:number,blockId:number, onDelete, isBoolHabit, state ,
  saveStyle,addHabitEntry ,editHabitName, separation = 5 , margin=defaultMargin) =>  {
  let params = getDefaultParams(state, blockId);
  let [background , setBackground] = useState(params.bg);
  let [habitColor, setHabitColor] = useState(params.habitColor);
  let [textColor, setTextColor] = React.useState(params.textColor);

  let [habitClickedData , setHabitClickedData] = useState(0); // changes to a numerical or boolea
  let [habitClickedDate , setHabitClickedDate] = useState('2020-10-10');
  let [habitName , setHabitName] = useState(params.habitName);
  console.log("HABIT NAME IS: " , habitName)
  const debounceHabitName = useDebouncedCallback((value) => {
    console.log('in debounce state isL ' , state);
    setHabitName(value);
    state.layout[0].layout[blockId].blockData.habitName = value;
    editHabitName(state.auth.me.id  , {name:value , blockId: blockId} , null)
    //saveHabitName(state.auth.me.id  , state.layout[0] , null);
    //saveHabitName(state.layout , state.auth , blockId)
    console.log('done w debounce');
  }, 900);
  let [showHabitPopUp , setShowHabitPopUp] = useState(false);



  // changedProperty is given to the Toolbox and set to true if the toolBox changes 
  // on a hover out of the widget it is checked if true --> if so then communicate changes to db
  // done this way because users mess around with the color changers a lot and it wont make sense
  // to tell the db about every change
  let [changedProperty , setChangedProperty] = useState(false); 
  let [hoverOverRect , setHoverOverRect] = useState(false);//
  let [hoveringOverDateData , setHoveringOverDateData] = useState<hoverOverDateType>({x:0 , y:0 , date:''});// x: n px, y: n px , date: Wed Feb 22 2023

  let habitData = genHabitData(params.habitEntrys );
  const colorMax = max(habitData, (d) => max(bins(d), count));
  let lowHabitColor = habitColor.slice(0,-1) + ',0.4)'
  let HighHabitColor = habitColor.slice(0,-1) +  ',1)';
  const rectColorScale = scaleLinear<string>({
    range: [habitColor, habitColor],
    domain: [0, colorMax],
  });
  const opacityScale = scaleLinear<number>({
    range: [0.09, 1],
    domain: [0, colorMax],
  });



  function stopPropagation(event: {target: any;stopPropagation: () => void; }){
    if(event.target.id !== "MoveBox"){
      event.stopPropagation();
    }
  };


  let [hovering, setHovering] = React.useState(false);

  // bounds


  
  let habitNameHeight = 45;
  let habitNameWidth = width;

  let dayLabelWidth = 50;
  let monthLabelsHeight = 32;
  let dayLabelHeight = height - habitNameHeight - monthLabelsHeight;

  let monthLabelsWidth = width - dayLabelWidth;


  const plotWidth = width - dayLabelWidth;
  const plotHeight = height - habitNameHeight - monthLabelsHeight;


  let binSpacing = 3
  const binWidth = (plotWidth - 51*binSpacing) / 51;
  const binHeight = (plotHeight - 7*binSpacing) / 7;
  const radius = min([binWidth, binHeight], (d) => d) / 2;


  const size =  plotWidth - margin.left - margin.right - separation;
  const xMax = size / 2;
  const yMax = height - margin.bottom - margin.top;
  xScale.range([0, xMax]);
  yScale.range([yMax, 0]);
  
  function MouseOver(event) {
    setHovering(true);
}
  function MouseOut(event){
    setHovering(false);
    if(changedProperty){
      let updatedStyle = {habitColor: habitColor , bg: background, textColor: textColor , }
      console.log(saveStyle , typeof saveStyle)
      updateStyles(state.layout , state.auth , blockId , updatedStyle , saveStyle);
      setChangedProperty(false);
    }
  }



  function handleHabitNameChange(e){
    //setHabitName(e.target.value);
    setHabitName(e.target.value);
    debounceHabitName(e.target.value);
}
 let months = getLast12Months();
  return (
    <div onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut}>
      {hovering && <HeatMapToolBox onChangedProperty={setChangedProperty} blockId={blockId} onDelete={onDelete} onChangeHabitColor={setHabitColor} onChangeTextColor={setTextColor} onChangeBackground={setBackground}></HeatMapToolBox>}
      <HeatMapContainer $width={width} $height={height} onMouseDown={stopPropagation} onTouchStart={stopPropagation} backgroundColor={background}>
          
          <HabitNameContainer $width={habitNameWidth} $height={habitNameHeight}>
          <HabitNameInput value={habitName} onChange={handleHabitNameChange} backgroundColor={background} fontColor={textColor}></HabitNameInput>
          </HabitNameContainer>
          <MonthLabelContainer $height={monthLabelsHeight} $width={monthLabelsWidth}>
            { months.map((month) =>  <DayLabel fontColor={textColor}>{month}</DayLabel>) }
          </MonthLabelContainer>
          <DayLabelContainer $width={dayLabelWidth} $height={dayLabelHeight}>
              <DayLabel fontColor={textColor}>Mon.</DayLabel>
              <DayLabel fontColor={textColor}>Tues.</DayLabel>
              <DayLabel fontColor={textColor}>Wed.</DayLabel>
              <DayLabel fontColor={textColor}>Thur.</DayLabel>
              <DayLabel fontColor={textColor}>Fri.</DayLabel>
              <DayLabel fontColor={textColor}>Sat.</DayLabel>
              <DayLabel fontColor={textColor}>Sun.</DayLabel>
          </DayLabelContainer>
          <PlotContainer $width={plotWidth} $height={plotHeight}>
              <svg width={plotWidth} height={plotHeight}>
                  <HeatmapRect
                  data={habitData}
                  xScale={(d) => xScale(d) ?? 0}
                  yScale={(d) => yScale(d) ?? 0}
                  colorScale={rectColorScale}
                  opacityScale={opacityScale}
                  binWidth={binWidth}
                  binHeight={binHeight}
                  gap={0}
                  top={0}
                  left={0}
                  >
                  {(heatmap) =>
                      heatmap.map((heatmapBins) =>
                      heatmapBins.map((bin) => (
                          
                          <rect
                          key={`heatmap-rect-${bin.row}-${bin.column}`}
                          className="visx-heatmap-rect"
                          width={binWidth}
                          height={binHeight}
                          x={bin.column * (bin.width + binSpacing)}
                          y={bin.row * ( bin.height + binSpacing)}
                          fill={bin.color}
                          fillOpacity={bin.opacity}
                          rx={2}
                          onClick={() => {
                            console.log('clicked habit bin!'); 
                            if(bin.column===50){
                              setHabitClickedDate(currentWeekDates[bin.row])
                            }
                            else{
                              setHabitClickedDate(datesList[bin.column * 7 + bin.row])
                            }
                            setShowHabitPopUp(true);
                          }}
                          onMouseOver={() => {
                            setHoverOverRect(true);
                            let date;
                            if(bin.column===50){
                              date = currentWeekDates[bin.row]
                            }
                            else{
                              date = datesList[bin.column * 7 + bin.row]
                            }
                            setHoveringOverDateData({x: (bin.column * (bin.width + binSpacing)) + dayLabelWidth + 20, 
                              y:(bin.row * ( bin.height + binSpacing)) + habitNameHeight + monthLabelsHeight + 20, 
                              date: date
                             })
                             setHoverOverRect(true);
                          }}
                          onMouseLeave={() => setHoverOverRect(false)}
                          />
                      )),
                      )
                  }
                  </HeatmapRect>
              </svg>

          </PlotContainer>
      </HeatMapContainer>
      {hoverOverRect && <HoverOverRectPopUp x={hoveringOverDateData.x} y={hoveringOverDateData.y} textColor={textColor} background={background}>{hoveringOverDateData.date}</HoverOverRectPopUp>}
      {showHabitPopUp && <AddHabitPopUp blockId={blockId} showWindow={setShowHabitPopUp} width={width * .67} height={height * .67} color={background} textColor={textColor} isBoolHabit={isBoolHabit}
                              date={habitClickedDate} habitName={habitName} state={state} addHabitEntry={addHabitEntry} entryData={habitClickedData} ></AddHabitPopUp> }
  </div>
  );
}
const mapStateToProps = (state) => ({
    state: state,
  });
export default compose(connect(    mapStateToProps , {addHabitEntry , saveStyle} ,null ))(HeatMapTracker);


