import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';
import styled from "styled-components";
import React from 'react';
import  { CalendarContainer,EventDetail , EventContainter,EventButton}  from './CalendarStyles';
import { CalendarToolBox } from './CalendarToolbox';
import { AddEvent } from './AddEventPopUp';
import { addCalendarItem , editCalendarItem } from '../../../../store/actions/calendarActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux'
import { EventEdit } from './EditEventPopUp';
import { updateStyles } from '../helpers';
import { getDefaultParams } from './CalendarHelper';
import { saveStyle } from '../../../../store/actions/blockActions';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];



function formatDate(originalDate){
  /*
  input: '2024-04-09T07:00:00.000Z'
  output: '2024-04-09'
  */
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
  const day = String(originalDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

function getCalendarContent(calendarData){
  console.log(calendarData)
  return calendarData
}

export const CalendarBlock = (blockHeight:number,blockWidth:number,blockId:number,onDelete: any,state , saveLayout , saveStyle) => {
  let params = getDefaultParams(state, blockId);
  const [value, onChange] = useState<Value>(new Date());
  const [events, setEvents] = useState([]);
  let [hovering, setHovering] = useState(false);
  let [background , setBackground] = useState(params.bg);
  let [textColor , setTextColor] = useState(params.textColor);
  let [showCalendarEventPopUp ,setShowCalendarEventPopUp ] = useState(false);
  let [showCalendarItemDetail ,setShowCalendarItemDetail ] = useState(false);
  let [eventDetails , setEventDetails] = useState<any>({});
  let [dayClicked , setDayClicked] = useState(new Date());
  let [showAllEventsInDay , setShowAllEventsInDay] = useState(false);
  let [allEventsInDay , setAllEventsInDay] = useState([]);
  let [changedProperty , setChangedProperty] = useState(false); 
  const calendarContent = useSelector((state) => state.calendar);
  const dispatch = useDispatch();
  const dayHeight = (blockHeight - 80) /6;
  const eventHeight = 25;
  function calendarItemClickedPopUp(e , eventData){
    setShowCalendarEventPopUp(false);
    setEventDetails(eventData);
    setShowCalendarItemDetail(true);

    e.stopPropagation();
  }
  function getDateItems(data){
    // sort by earliest start time date
    data.sort((a, b) => {
      // Assuming each timerange array contains only one date string
      let dateA = new Date(a.timeRange[0]).getTime();
      let dateB = new Date(b.timeRange[0]).getTime();
      return dateA - dateB;
    });
    let numItemToShow = Math.floor(dayHeight / eventHeight) - 1;
    if(numItemToShow < data.length){
      let events = data.slice(0,numItemToShow-1).map((event) => {return <EventButton textColor={textColor} backgroundColor={background} onClick={(e) => calendarItemClickedPopUp(e , event)}>{event.title}</EventButton>})
      events.push(<EventButton onClick={() => {setAllEventsInDay(data);setShowAllEventsInDay(true)}} textColor={textColor} backgroundColor={background}>{data.length - numItemToShow} more</EventButton>)
      return events
    }
   else{//event.stopPropagation();
    return (data.map((event) => {return <EventButton onClick={(e) => calendarItemClickedPopUp(e , event)} textColor={textColor} backgroundColor={background}>{event.title}</EventButton>}))
   }
  }
  // @ts-ignore

  function tileContent({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      for(var date2 in calendarContent){
        //console.log(date.toISOString() , date2)
        //console.log('date , date2' , date , date2)
        if(formatDate(date) === date2){
          //console.log(date2)

          return <EventContainter className="eventView">
                  { getDateItems(calendarContent[date2])}
                </EventContainter>
        }
      }
      return ''
      //}

    }
  }
  function MouseOver(event) {
      setHovering(true);
  }
  function MouseOut(event){
    setHovering(false)
    if(changedProperty){
      let updatedStyle = {bg: background, textColor: textColor}
      //updateStyles(state.layout , state.auth , blockId , updatedStyle , saveLayout);
      updateStyles(state.layout , state.auth , blockId , updatedStyle , saveStyle);
      setChangedProperty(false);
    }
  }

  function onSaveCalendarItem(){

  }
  function stopPropagation(event: {target: any;stopPropagation: () => void; }){
    if(event.target.id !== "MoveBox"){
      event.stopPropagation();
    }
  };

  function onDayClicked(day){
    setDayClicked(day);
    setShowCalendarEventPopUp(true);
    setShowCalendarItemDetail(false);
  }

  function onChangeCalendarBackground(color:string){
    setBackground(color);
  }
  return <div onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut}>
  <CalendarContainer $buttonHeight={dayHeight} textColor={textColor} backgroundColor={background} onHoverBackground={background}>
    {hovering && <CalendarToolBox onChangedProperty={setChangedProperty} blockId={blockId} onDelete={onDelete}  onChangeBackground={onChangeCalendarBackground} onChangeTextColor={setTextColor}></CalendarToolBox>}
    <Calendar showFixedNumberOfWeeks={true} tileContent={tileContent} onChange={onChange} value={value} onClickDay={(value: any,event: any) => { setDayClicked(value);setShowCalendarEventPopUp(true);setShowCalendarItemDetail(false);} } /> 
    {showCalendarEventPopUp && <AddEvent  addCalendarItem={addCalendarItem} state={state} setActiveAddEvent={setShowCalendarEventPopUp} width={blockWidth * .67} height={blockHeight * .67} color={background} startDate={dayClicked}/>}
    {showCalendarItemDetail && <EventEdit editCalendarItem={editCalendarItem} state={state} eventData={eventDetails} setActiveEditEvent={setShowCalendarItemDetail} width={blockWidth * .4} height={blockHeight * .4} backgroundColor={background}/>}
  </CalendarContainer>
</div>
}
const mapStateToProps = (state) => ({
  state:state
});
export default compose(connect(    mapStateToProps , {addCalendarItem , editCalendarItem , saveStyle} ,null ))(CalendarBlock);


