import React from "react"
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import HamBurger from './gifs/hamburg.gif'
import BgChange from './gifs/bgChange.gif'
import MoveDemo from './gifs/move.gif'
import ResizeDemo from './gifs/resize.gif'
let Container = styled.div`
    height:75%;
    z-index:99999;
    background-color: rgba(0,0,0,0.87);
    width:75%;
    borderRadius: 16px;
    position:absolute;
    top: 100px;
    left: 12.5%;
    border-radius:16px;
`

// page 1


export const Tutorial = (onClose) => {

  let [hovering, setHovering] = useState(false);
  let [sliderPageIndex , setSliderPageIndex] = useState(0);
  /*document.addEventListener('contextmenu',(event) => {
      event.preventDefault();
      console.log(event.type);
    });*/
    function MouseOver(event) {
      setHovering(true);
    }
    function MouseOut(event){
      setHovering(false);
    }
  function stopPropagation(event: {target: any;stopPropagation: () => void; }){
      if(event.target.id !== "MoveBox"){
        event.stopPropagation();
      }
    };
  console.log("IMG HOVERING", hovering)
  function nextClick(){
    if(sliderPageIndex !== 3){
      setSliderPageIndex(sliderPageIndex + 1);
    }
  }
  function prevClick(){
    if(sliderPageIndex !== 0){
      setSliderPageIndex(sliderPageIndex - 1);
    }
  }
  return<Container>
          <div style={{display: 'flex' , justifyContent: 'right' , width:'100%'}}>
            <button style={{borderRadius:'5px'}} onClick={onClose.onClose} >Exit</button>
          </div>

          <div>{sliderPageIndex}</div>
          {sliderPageIndex === 0 ? 
              <>
            <h1 style={{color:'white' , textAlign:'center'}}>Getting Started</h1> 
            <h2 style={{color:'white' , textAlign:'center'}}>To customize your dashboard first add a widget by clicking the top left button!</h2>
            <img style={{paddingLeft: '15%' , width: '70%' ,height: '58%'}} src={HamBurger}></img>
            </>
            : null}
            {sliderPageIndex === 1 ? 
            <>
            <h2 style={{color:'white' , textAlign:'center'}}>Customize the widget's style by clicking the widgets style button </h2>
            <img style={{paddingLeft: '15%',width: '70%', height: '58%'}} src={BgChange}></img>
            </>
            : null}
            {sliderPageIndex === 2 ? 
            <>
            <h2 style={{color:'white' , textAlign:'center'}}>Move the widget by clicking the move button and dragging the widget </h2>
            <img style={{paddingLeft: '15%',width: '70%', height: '67%'}} src={MoveDemo}></img>
            </>
            : null}
            {sliderPageIndex === 3 ? 
            <>
            <h2 style={{color:'white' , textAlign:'center'}}>Resize the widget by moving that bottom right button!</h2>
            <img style={{paddingLeft: '15%',width: '70%', height: '67%'}} src={ResizeDemo}></img>
            </>
            : null}
            <div style={{position: 'absolute',bottom: '20px',display: 'flex',flexDirection: 'column',alignItems: 'center',width: '100%'}}>
              <div style={{width: '33%'}}>
                <Slider value={sliderPageIndex} min={0} max={3} dots={true} />
              </div>
              <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center', width: '33%'}}>
                { sliderPageIndex !== 0  && <button style={{borderRadius: '5px', marginRight: '10px'}} onClick={prevClick}>Prev</button> }
                { sliderPageIndex !== 3 ? <button style={{borderRadius: '5px'}} onClick={nextClick}>Next</button> : 
                      <button style={{borderRadius:'5px'}} onClick={onClose.onClose} >Exit</button> }
              </div>
            </div>

      </Container>

}

export default Tutorial;
