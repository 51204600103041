import React from "react";


import {NotesBlock} from "./Notes/Notes"
import { NewsBlock } from "./News/NewsBlock";
import {CalendarBlock} from "./Calendar/Calendar";
import { ImageBlock } from "./Image/imageBlock";
import { YoutubeBlock } from "./Youtube/YoutubeBlock";
import { EmailBlock } from "./Email/Gmail/Email";
import { HeatMapTracker } from "./HeatMapTracker/HeatMap";
import { saveLayout } from "../../../store/actions/userActions";
import { noteUpdate } from "../../../store/actions/noteActions";
import { saveImage } from "../../../store/actions/imageActions";
import { saveYoutube } from "../../../store/actions/youtubeActions";
import { addHabitEntry,editHabitName } from "../../../store/actions/HabitActions";
import { saveStyle } from "../../../store/actions/blockActions";
import { compose } from 'redux';
import { connect } from 'react-redux';
const BlockFactory = (props: {state:any , blockData:any ,saveLayout:any, saveImage,saveYoutube, 
  saveStyle,btype:string,width:number,height:number, onDelete: any , blockId:number,
  addHabitEntry , editHabitName , noteUpdate}
   ) => {

    // goal: 50
    // 5 * 11.5
    // 1 * 27 - 20 = 148


    //goal: 722
    //61 * 11 = 671
    console.log(props.btype , props.height)
  let blockHeight = 11.95 * props.height - 15;//40*(props.height-1);
  let blockWidth = (window.innerWidth/96)*props.width - (20);
  let blockId = props.blockId;
  let onDelete = props.onDelete;
  let blockData = props.blockData;
  //console.log("BYTpe ON CREATION: " , props.btype , blockId )
  //Now conditionally render based on btype!!!!!:]
  if(props.btype === "News"){
    return NewsBlock(blockWidth,blockHeight, blockId, onDelete , props.state , props.saveStyle);
  }
  if(props.btype === "Youtube"){
    return YoutubeBlock({blockWidth:blockWidth,
                          blockHeight:blockHeight,
                          blockId:blockId,
                          onDelete:onDelete ,
                          blockData:blockData,
                          state:props.state , saveYoutube:props.saveYoutube});
  }
  if(props.btype === "Calendar"){
    return CalendarBlock(blockHeight,blockWidth,blockId,onDelete,props.state , props.saveLayout , props.saveStyle);//CalendarBlock(blockWidth,blockHeight );
  }
  if(props.btype === "Image"){
    /* edit button having
    */
    return ImageBlock(blockWidth,blockHeight,blockId,onDelete , blockData,props.state,props.saveImage );
  }
  if(props.btype === "Email"){
    //console.log("***EMAIL ID*** " , blockId);
    return EmailBlock(blockHeight,blockWidth ,blockId, props.state , onDelete , props.saveLayout);
  }
  if(props.btype === "CheckHabit"){
    return HeatMapTracker(blockWidth,blockHeight,blockId, onDelete, true  , props.state,props.saveStyle, props.addHabitEntry,props.editHabitName)
  }
  if(props.btype === "NumberHabit"){
    return HeatMapTracker(blockWidth,blockHeight,blockId, onDelete, false  , props.state , props.saveStyle,props.addHabitEntry ,props.editHabitName)
  }
  if(props.btype === "Notes"){
    return NotesBlock(blockWidth,blockHeight,blockId, onDelete, props.saveStyle  , props.state ,props.noteUpdate)
  }
  return (
    <div>{props.btype}</div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  state: state
});
export default compose(connect(    mapStateToProps , {noteUpdate , saveLayout , saveImage , saveYoutube , saveStyle , addHabitEntry , editHabitName} ,null ))(BlockFactory);