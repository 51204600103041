

import { FunctionComponent,forwardRef,useImperativeHandle, useState, useEffect,useRef, Children, Component, JSX } from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles.css";
import BlockFactory  from "./Blocks/BlockFactory";
import styled from "styled-components";
import React from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { saveLayout , loadLayouts } from '../../store/actions/userActions';
import { useHistory } from "react-router-dom";
import requireAuth from '../../hoc/requireAuth';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  width?:number,
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  blockTypes: string[];
  background:any;
  saveLayout:any;
  loadLayouts:any;
  auth:any;
  state:any;
  gridLayout:any;
}




const ResponsiveReactGridLayout = WidthProvider(Responsive);
//const DropDrag: FunctionComponent<Props> = (props) => {
const DropDrag = forwardRef((props:Props,ref) => {
  
  console.log('in grid ' , props)

  

  const history = useHistory();

  const mongodbLayout = null;//maybe save this as a cookie?
  function saveLayoutChangesPagnator(){
    /*
    If saving change on EVERY layout change is costly , may have to implment this function
    TO DO 
      1.this function should run every ~45 seconds
      2. it should compare the current layout to whats in mongodblayout is
      3. if they are different then update it
      
    */

  }

  const [layouts, setLayouts] = useState<any>({
    //25 blocks
    // this should eventually get its data from localstorage
    // if localstorage has nothiing then it should get it from Mongodb
    lg:props.gridLayout
    

  });
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [compactType, setCompactType] = useState<string | null>(null);
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: []
  });

  useEffect(() => {
    setMounted(true);
  }, []);
  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || []
    });
  };

  const onCompactTypeChange = () => {
    let oldCompactType = "";

    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    setCompactType(compactType);
  };

 const onLayoutChange = async (layout: any) => {
  /*
  layout has updated positoning!
  layout is longer too!


  layout has block data on second add
  */
    let newLayout = {
      layout: [],
      background:props.background,
      username:props.auth.me.username
    }
    for(let i = 0;i<layout.length;i++){


      let bdata = {}
      let btype = ''
      let id = 0;
      if(layout[i].blockData){
        bdata = layout[i].blockData;
        btype = layout[i].blockType;
        id = layout[i].i
      }
      else{
        bdata = layouts.lg[i].blockData;
        btype = layouts.lg[i].blockType;
        id = layouts.lg[i].i;
      }
      let block = {
        x : layout[i].x,
        y : layout[i].y,
        w : layout[i].w,
        h : layout[i].h,
        blockData: bdata,
        blockType: btype,
        i: id
      }
      newLayout.layout.push(block)

    }
    console.log('after loopty loop' , newLayout);
    if(JSON.stringify(layouts) === JSON.stringify([])) { return;}
    if(props.state.layout["NOT LOADED"] === 1){
      console.log("IN EMPTY IF")
      const token = props.auth.token;
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      };
      if (token) {
        config.headers['x-auth-token'] = token;
      }
      //console.log("First render.... now get layouts from mongoDb");
      loadLayouts(props.auth.me.username,history);
    }

    
    setLayouts( {lg: newLayout.layout});
    if(JSON.stringify(layouts.lg) !== JSON.stringify(props.state.layout)){
      props.saveLayout(props.auth.me.id , newLayout , {} );
    }
    //layouts.lg = newLayout.layout
    
  };
  //functions here allow some communication with the grid and it's parent
  useImperativeHandle(ref, () => ({
    async changeLayout () {
      /*if(layouts){
        if(layouts.lg !== undefined){
          console.log('layout.lg',layouts)
          before = { ... layouts.lg}
        }
        
      }*/
      /*if(layouts.lg.length < props.blockTypes.length){
       var y = Math.ceil(Math.random() * 4) + 1;
       let newBlock =  {
         x: (_.random(0, 4) * 2) % 12,
         y: Math.floor((props.blockTypes.length) / 6) * y,
         w: 4,
         h: 4,
         i: Math.random().toString(),
         static: false,
       }
       layouts.lg.push(newBlock);
      }*/
      console.log('on add in grid' , layouts , props.gridLayout)
      onLayoutChange(props.gridLayout)
     }
    
  }

    ));
  const onDrop = (layout: any, layoutItem: any, _ev: any) => {
    alert(
      `Element parameterssss:\n`+ JSON.stringify(layoutItem  )
    );
  };

  const changeType = (key:string) =>{
    console.log("CLICK CLACK" , key);
  }
  //useEffect(() => {console.log("change block logic here")},[props.block])

  const delteBlock = (blockId:number) => {
    console.log("TO DO delte block in here" , layouts.lg);
    for(let index = 0;index < layouts.lg.length; index++){
      if(index === blockId){
        layouts.lg.splice(index,1);
        props.blockTypes.splice(index,1);
        console.log("REMOVED widget!", layouts);
        setLayouts({...layouts});
        //save layouts again in db
        break;
      }
    }
  }
  const generateDOM = () => {
    console.log('***gen dom***\n',layouts.lg)

    let index  = -1;
    return _.map
    (layouts.lg, function (l, i) {
      index += 1
      return (
        <div key={l.i}>
          <BlockFactory state={props.state} blockData={l.blockData} blockId={index} btype={l.blockType} width={l.w} height={l.h} onDelete={(blockId:number) => delteBlock(blockId)}></BlockFactory>
        </div>
      );
    });
  };
  const onWidthChange = (containerWidth: number,margin: [number, number],cols: number,containerPadding: [number, number]) => {
      //to do make shit work when user changes window size
      //console.log('width changed: ' , containerWidth);
  };

  let b = "https://i.pinimg.com/originals/ce/90/99/ce9099aa790ddfa5f2767d9dbd015a67.jpg"
  // background:props.background;
  let StyledGrid = styled.div`
    height:100%;
    width:100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover ;

    height:"100%",
    "background-position": "center",
    width:"100%",
    "background-repeat": "no-repeat",
    "background-size": "cover" ,
  `
  let background = "https://i.pinimg.com/originals/ba/53/2b/ba532bf3651dafbc0436ba6357b16c5a.jpg"
  let gif = "https://i.pinimg.com/originals/55/fc/4b/55fc4bee7c31f0850262da53fa1e3180.gif"//'https://64.media.tumblr.com/587156531ea4a91473adeadbc6af0e13/b865992b21c858ce-23/s640x960/85438340b94ece05f9ef87e75e77bdff7382fa87.gifv'
  
  let gridStyle = {};
  function validateColor(obj) {
    return obj &&
        typeof obj.r === 'number' &&
        typeof obj.g === 'number' &&
        typeof obj.b === 'number' &&
        typeof obj.a === 'number';
}
  if(props.background === undefined){
    console.log("no background set yet")
    gridStyle = {backgroundColor:  "rgba(106,115,128,1)"};
  }
  else if(validateColor(props.background)){
    let r = props.background.r.toString() + ',';
    let g = props.background.g.toString()+ ',';
    let b = props.background.b.toString()+ ',';
    let a = props.background.a.toString()
    gridStyle = {backgroundColor:  "rgba( " + r + g + b + a + ")"}
    //gridStyle = {backgroundColor:  props.background}
  }
  else if(props.background.includes("https")){
    gridStyle = {  
      backgroundImage: "url(" + props.background + ")",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%'
    }
  }//backgroundSize: 'cover',
  else{
    //gridStyle = {backgroundColor:  props.background , 'background-size': props.width.toString() + 'px ' +  props.rowHeight.toString() + 'px'}
    gridStyle = {backgroundColor:  props.background , 'background-size': '100% 100%'}
  }

  console.log("GRID PROPS: " , props)
  return (
    <>
      <div className="mb-4">
        <div>
        <ResponsiveReactGridLayout
          {...props}
          layouts={layouts}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          onWidthChange={onWidthChange}
          onDrop={onDrop}
          isDroppable
          style={gridStyle}
        >
          {generateDOM()}
        </ResponsiveReactGridLayout>
        </div>
      </div>
    </>
  );
});

const mapStateToProps = (state) => ({
  auth: state.auth,
  state:state
});
export default compose(connect(    mapStateToProps , {saveLayout,loadLayouts } ,null, {forwardRef: true} ))(DropDrag);

