
import styled from "styled-components";
const EmailContainer = styled.div<{ $color?:string,$textColor?:string,$backgroundColor?: string;$height?: number;$width?: number; }>`
  padding: 0.5em;
  margin: 0.5em;
  background-color: ${props => props.$backgroundColor || "rgba(0,0,0,0)"};

  height: ${props => props.$height || 400}px;
  width: ${props => props.$width || 400}px;
  overflow: auto;
  color: ${props => props.$color || "black"};
  
`;
let ToolBoxContainer = styled.div`position: absolute;
    pointer-events:auto;`
// sender   -----snippet-----      date
//  200px     (width - 200 - 50px)    50px
const SearchBar = styled.input<{fontColor:string , backgroundColor:string}> `
	width: 70%;
	padding: 0 30px 0 0px;
  padding-left:20px;
  height:50px;
	border-style: solid;
	border-radius: 100px;
  border-color: ${props => props.fontColor };
	background-color: ${props => props.backgroundColor};
  color: ${props => props.fontColor };
  display: inline-block;
  float:left;
`
const EmailPreviewRow = styled.div<{ $color?:string,$backgroundColor?: string;$height?: number;$width?: number; }>`
  display: table;
  width: 93%; /*Optional*/
  table-layout: fixed; /*Optional*/
  border-spacing: 10px; /*Optional*/
  color: ${props => props.$color || "black"};
`;

const EmailMainView = styled.div<{ $color?:string,$backgroundColor?: string;$height?: number;$width: number; }>`
  width: 100%;
  height:100%;
  background-color: ${props => props.$backgroundColor || "rgba(0,0,0,0)"};
  color: ${props => props.$color || "#BF4F74"};
  white-space: break-spaces;
`;
const Snippet = styled.div<{$color?:string,$backgroundColor?: string,$textColor?: string; $width:number;}>`
  display: table-cell;
  color: ${props => props.$textColor || "#000000"};
  z-index:100; 
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${props => props.$width}px;
  white-space: nowrap;
  
  color: ${props => props.$color || "black"};
  `
  const Subject = styled.div<{color?:string,$backgroundColor?: string,$textColor?: string; $width:number;}>`
  display: table-cell;
  
  color: ${props => props.$textColor || "#000000"};
  z-index:100; 
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${props => props.$width}px;
  white-space: nowrap;
  font-weight: bold;
  color: ${props => props.color || "black"};
  `

const Date = styled.div<{color?:string,$backgroundColor?: string,$textColor?: string; }>`
  display: table-cell;
  
  color: ${props => props.$textColor || "#000000"};
  z-index:100; 
  width:100px;
  color: ${props => props.color || "black"};
  
`
const Sender = styled.div<{color?:string,$backgroundColor?: string,$textColor?: string; }>`
  display: table-cell;
  
  color: ${props => props.$textColor || "#000000"};
  z-index:100; 
  width:100
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
  color: ${props => props.color || "black"};
`
const InboxList = styled.div<{color?:string,$backgroundColor?: string,$width:number ,$height:number }> `
    table-layout: fixed;
    height: ${props => props.$height || 400}px;
    width: ${props => props.$width}px;
    color: ${props => props.color || "black"};
    background-color: ${props => props.$backgroundColor || "rgba(0,0,0,0)"};
    `
const MenuButton = styled.select<{color?:string}>`
    /* Reset */
    border: 1px solid  ${props => props.color || "black"};;
    color:${props => props.color || "black"};;
    height:50px;
    display: inline-block;
    float:right;
    appearance: none;
    outline: 0;
    font: inherit;
    /* Personalize */
    width: 20%;
    background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
      linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
    border-radius: 10px;
    box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    /* Remove IE arrow */
    &::-ms-expand {
      display: none;
    }
    /* <option> colors */
    option {
      color: pink;
      background-color: var(--option-bg);
    }
    `
const SendButton = styled.button`
  position: absolute;
  pointer-events:auto;
  z-index:100; 
  width:15%
`
const BackButton = styled.button`
  position: absolute;
  pointer-events:auto;
  z-index:100; 
  right: 5px;
  width:65px;
  height:30px;
  border-radius:7px;
`
const ReplyButton = styled.button`
  position: absolute;
  pointer-events:auto;
  z-index:100; 
  right: 90px;
  width:65px;
  height:30px;
  border-radius:7px;
`
const Row = styled.div<{$textColor?: string;$spacing?:string }>`
    display: table-cell;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
    border-spacing: ${props => props.$spacing || "10px"};
    color: ${props => props.$textColor || "black"};
`;
export {ToolBoxContainer,ReplyButton,EmailContainer ,BackButton , EmailPreviewRow ,InboxList,Snippet , Date , Sender  , EmailMainView,Subject , SearchBar,MenuButton,SendButton,Row };


/*
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    white-space: nowrap;

*/