
import axios from 'axios'


let base_url = process.env.REACT_APP_BASE_URL;
if(process.env.NODE_ENV !== "production"){
  base_url = "https://localhost:80"
}
export const attachTokenToHeaders = (getState) => {
    const token = getState().auth.token;
  
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
  
    if (token) {
      config.headers['x-auth-token'] = token;
    }
  
    return config;
  };
export const noteUpdate = (id, item, history) => async (dispatch, getState) => {
    console.log("**********IN note update item" , id, item,history, '*********');
    try{
        console.log('loading... add habit action')
        const options = attachTokenToHeaders(getState);
        const response = axios.put(base_url + `/notes/updateNote/${id}`, item, options);
        //console.log("RESPONSE save habit item " , response);
        dispatch({
          type: "noteUpdate",
          payload: item,
        });
    }
    catch{
        console.log("error in calendar save action")
    }
  };