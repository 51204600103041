

import React, { useState } from "react";
import { ToolBoxContainer } from "./EmailStyles";
import { RgbaColorPicker} from "react-colorful";
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import styled from "styled-components";
let Focus = styled.span`position: absolute;`
export const EmailToolBox = (props: {blockId:number,onDelete(arg0: number): unknown, onChangeBackground(arg0:any ): unknown; onChangeTextColor(arg0:any ): unknown;  onChangedProperty(arg0:boolean): unknown;
}) => {

    
    let options =  ['Select','Delete Widget', 'Change Background' , 'Change Text Color'];
    const [showOptions , setShowOptions] = useState(false);
    //const [background, setBackground] = React.useState('');
    const [showColorPicker, setShowColorPicker] = React.useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = React.useState(false);
    function handleTextColorChange(e) {
        console.log(e)
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeTextColor(color);
        props.onChangedProperty(true);
    }
    function handleColorChange(e) {
        console.log(e)
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeBackground(color);
        props.onChangedProperty(true);
    }
    let onEvent =  (e: { target: { value: any; }; }) => {
        let eventType = e.target.value;
        if(eventType === 'Delete Widget'){
            props.onDelete(props.blockId);
            console.log('delete click')
        }
        else if(eventType === 'Change Background'){
            setShowColorPicker(true)
        }
        else if(eventType === 'Change Text Color'){
            setShowTextColorPicker(true)
        }
    }

    function onOptionClick(){
        if(showOptions){
            setShowOptions(false);
            setShowColorPicker(false)
        }
        else{
            setShowOptions(true);
        }
    }
    return <ToolBoxContainer>
            <div onClick={onOptionClick}><OptionBox/></div>
            {showOptions && <select id='imageToolBox' onChange={onEvent}>
                <optgroup/>
                {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
                ))}
            </select>
            }
            <Focus/>
            {
                showColorPicker ? <RgbaColorPicker onChange={handleColorChange} /> : ''
            }
            {
                showTextColorPicker ? <RgbaColorPicker onChange={handleTextColorChange} /> : ''
            }
            <MoveBox/>
        </ToolBoxContainer>
}