

import React, { useState , useEffect, useRef } from "react";
import styled from "styled-components";

import { RgbaColorPicker} from "react-colorful";
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import listenForOutsideClick from "../../ToolBox/listen-for-outside-clicks";

let ToolBoxContainer = styled.div`position: absolute;pointer-events:auto;z-index:1000;`
export const CalendarToolBox = (props: {
    blockId:number,onDelete(arg0: number): unknown, onChangeBackground(arg0:any ): unknown, onChangeTextColor(arg0:any ): unknown , onChangedProperty(arg0:boolean): unknown;
}) => {

    let options =  ['', 'Delete Widget', 'Change Background', 'Change Text Color'];
    const [showColorPicker, setShowColorPicker] = React.useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = React.useState(false);
    const [showOptions , setShowOptions] = useState(false);

    //const menuRef = useRef(null);
    //const [listening, setListening] = useState(false);
    //const [isOpen, setIsOpen] = useState(false);
    //const toggle = () => setShowOptions(false);
    //useEffect(listenForOutsideClick(listening, setListening, menuRef, setShowOptions));
    //useEffect(listenForOutsideClick(listening, setListening, menuRef, setShowColorPicker));
    function handleColorChange(e) {
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeBackground(color);
        props.onChangedProperty(true);
    }
    function handleTextColorChange(e) {
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeTextColor(color);
        props.onChangedProperty(true);
    }
    let onEvent =  (e: { target: { value: any; }; }) => {
        let eventType = e.target.value;
        if(eventType === 'Delete Widget'){
            props.onDelete(props.blockId);
            console.log('delete click')
        }
        else if(eventType === 'Change Background'){
            setShowColorPicker(true)
        }
        else if(eventType === 'Change Text Color'){
            setShowTextColorPicker(true)
        }
    }
    function onOptionClick(){
        if(showOptions){
            setShowOptions(false);
            setShowColorPicker(false)
        }
        else{
            setShowOptions(true);
        }
    }
    return (<div ><ToolBoxContainer>
            <div onClick={onOptionClick}><OptionBox/></div>
            { showOptions ? <select className={"Option"} id='CalendarToolBox' onChange={onEvent}>
                {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
                ))}
            </select> : ''}
            {
                showColorPicker ? <RgbaColorPicker className={"Option"} onChange={handleColorChange} /> : ''
            }
            {
                showTextColorPicker ? <RgbaColorPicker className={"Option"} onChange={handleTextColorChange} /> : ''
            }
            <MoveBox/>
        </ToolBoxContainer></div>);
}

 