
import React from "react";
import styled from "styled-components";
let MoveDiv = styled.div`width:20px;height:20px; `
let Img = styled.img`width:20px;height:20px;`
export const MoveBox = () => {

    return <MoveDiv id="MoveBox">
                <Img src="https://cdn-icons-png.freepik.com/512/1839/1839552.png" alt="" />
            </MoveDiv>

}