
import { registerUserWithEmail } from '../../store/actions/registerActions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useState, useEffect } from "react";
import Cards from 'react-credit-cards';
import {loadStripe} from '@stripe/stripe-js';
import {Elements,PaymentElement,  useStripe,useElements, CardElement,} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { Link, withRouter, Redirect } from 'react-router-dom';
import backgroundImage from './ai_landscape.jpg';
import {FidgetSpinner} from 'react-loader-spinner';
const delay = ms => new Promise(res => setTimeout(res, ms));
async function readStreamResponse(response) {
  if (response.body) {
    const reader = response.body.getReader();
    const chunks = [];
    let done = false;

    while (!done) {
      const { value, done: streamDone } = await reader.read();
      if (value) {
        chunks.push(value);
      }
      done = streamDone;
    }

    // Combine the chunks into a single Uint8Array
    const totalLength = chunks.reduce((acc, chunk) => acc + chunk.length, 0);
    const fullResponseArray = new Uint8Array(totalLength);
    let offset = 0;
    for (let chunk of chunks) {
      fullResponseArray.set(chunk, offset);
      offset += chunk.length;
    }

    // Decode the Uint8Array into a string
    const fullResponse = new TextDecoder("utf-8").decode(fullResponseArray);
    console.log("Raw response:", fullResponse);
    // Try to parse it as JSON
    try {
      const jsonResponse = JSON.parse(fullResponse);
      console.log("Parsed JSON:", jsonResponse);
      return jsonResponse;
    } catch (error) {
      console.log("Non-JSON response:", fullResponse);
      return fullResponse;
    }
  } else {
    console.log("No body stream in response.");
    return null;
  }
}

let Alert = styled.div`padding: 10px;background-color: #f44336;color: white;margin-top: 10px;border-radius: 5px;`
let StyledInput = styled.input`
  background-color: white;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid lightblue;
  height:16px;
  width:200px;
  `
const StyledButton = styled.button`
  background-color: rgb(75 131 70 / 56%);  /* Button color */
  color: white;                /* Text color */
  border: none;                /* Remove border */
  border-radius: 5px;         /* Rounded corners */
  padding: 10px 20px;         /* Padding */
  font-size: 16px;            /* Font size */
  cursor: pointer;             /* Pointer cursor on hover */
  transition: background-color 0.3s ease;  /* Transition for hover effect */
  margin-bottom: 10px;
  &:hover {
    background-color: rgb(75 131 70);  
  }
  
  &:disabled {
    background-color: rgb(75 131 70 / 56%);  /* Gray when disabled */
    cursor: not-allowed;         /* Change cursor when disabled */
  }
`;
let base_server_url = "https://localhost:80";
if(process.env.NODE_ENV === 'production'){
  base_server_url = 'https://prod3-345a5a1ba04b.herokuapp.com'
}
const cardElementOptions = {
    style: {
      base: {
        color: "white",      // Font color
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',  // Font family
        fontSmoothing: "antialiased",
        fontSize: "16px",      // Font size
        "::placeholder": {
          color: "#aab7c4",   // Placeholder color
        },
      },
      invalid: {
        color: "#fa755a",      // Font color for invalid inputs
        iconColor: "#fa755a",  // Icon color for invalid inputs
      },
    },
    hidePostalCode: true,  // Hides postal code field if you don't need it
};

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}



/*
payment
        <div style={{width:'100%',display: 'flex', flexDirection: 'column' ,alignItems: 'center', justifyContent: 'center'}}>
          <div style={{width:'100%' ,maxWidth:'400px',alignItems: 'center', justifyContent: 'center'}}>
            <CardElement options={ cardElementOptions}/>
          </div>
          <br/>
          <div style={{alignItems: 'center', flexDirection: 'column' , justifyContent: 'center'}}>
            <table>
              <tr><td>{ !showAlert && <StyledButton onClick={ () => { setShowSpinner(true);  createSubscription() }} disabled={!stripe}>Subscribe</StyledButton>}</td></tr>

            
            <tr><td>{showSpinner && !showAlert && <FidgetSpinner backgroundColor='rgb(75 131 70)' visible={true} height="80" width="80" ariaLabel="fidget-spinner-loading" wrapperStyle={{}}wrapperClass="fidget-spinner-wrapper"/>}</td></tr>
            <tr><td> {paymentSuccess && <Redirect to='/login'/>}</td></tr>

            <tr><td>{showAlert && <Alert>Your Payment Was Succesful!</Alert>}</td></tr>
            </table>
            <br/>
            <div> </div>
          </div>
        </div>
*/

const Stripe =  (props) => {
  console.log(props)
  function NewForm() {
  
    // collect data from the user
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [paymentSuccess , setPaymentSuccess] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    // stripe items
    const stripe = useStripe();
    const elements = useElements();
  
    useEffect(() => {
      if (showAlert) {
        // Set a timer to hide the alert after 3 seconds
        const timer = setTimeout(() => {
          setShowAlert(false); // Hide alert after 3 seconds
          setPaymentSuccess(true);
        }, 3000);
  
        // Cleanup the timer when the component unmounts or on re-render
        return () => clearTimeout(timer);
      }
    }, [showAlert]);
    // main function
    const createSubscription = async () => { 
      try {
        
        // create a payment method
        const paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: elements?.getElement(CardElement)!,
          billing_details: {name,email},
        });
        console.log("PAYMENT METHOD IS: " , paymentMethod ,  base_server_url + "/create-subscription");
        // call the backend to create subscription
        let response = await fetch( base_server_url + "/create-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethod: paymentMethod,
            name: name,
            email: email,
            password:password
          }),
        })
        console.log('create sub response:',response)
        let subscription = await readStreamResponse(response);
        if(subscription.clientSecret){
          const confirmPayment = await stripe?.confirmCardPayment(
            subscription.clientSecret
          );
          if (confirmPayment?.error) {
            alert(confirmPayment.error.message);
          } else {
            // alert user of a successful payment then re-route to /grid

            setShowAlert(true)
            return <Redirect to='/grid'/>
          }
        }
        else{
          alert('bug ;[')
        }
  

      } catch (error) {
        console.log(error);
        
      }
    };

    const [freeAccountMade , setFreeAccountMade] = useState(false);
    
    return (
      <div  style={{backgroundColor:'rgba(0,0,0,0.69)' , width:'69%', borderRadius:'16px'}}>

        <div style={{width:'100%',display: 'flex', flexDirection: 'column' ,alignItems: 'center', justifyContent: 'center'}}>
          <h1 style={{color: 'aliceblue' , marginBottom: '0px' , fontFamily: 'serif'}}>Join The Beta</h1>
          <h1 style={{color: 'aliceblue' , fontFamily: 'serif'}}>For free!</h1>
          <br/>
          <StyledInput placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)}/>
          <br />
          <StyledInput placeholder="Email"  type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <br />
          <StyledInput placeholder="Password" type="text" value={password} onChange={(e) => setPassword(e.target.value)}/>
          <br />
          <StyledButton onClick={ () => {
            console.log('clicked now register!');
            props.registerUserWithEmail({email:email,name:name, password:password, username: generateRandomString(10)} , {})
            setFreeAccountMade(true);
            window.location.href='/login'
            }
            }
            >Sign Up</StyledButton>
        </div>
        {freeAccountMade && <Redirect to='/login'/>}
      </div>  

          

    );
}
  let [stripePromise , setStripe] = useState<any>(null);
  //let stripePromise = loadStripe('pk_test_51Q2O4oP3GJTgVeOLP070fCN29AXjVVwKjvgV1CEj8yXlMP11msiBrcKBLuhLViBQFlpHHHYO6zREVrZqXD08b4IS00apOtfOR4');
  useEffect( () => {
    const initializeStripe = async () => { setStripe( await loadStripe('pk_live_51Q2O4oP3GJTgVeOLmXYAG3bJ820aeo7HeGo9FoLRbXXYaA8s00We9HgLA2sF2jdK1IAvVcnzLXaaUAnEtzyAxsJ9000z0xaPXY'))}
    initializeStripe()
  } , [])
  const [message, setMessage] = useState("");
  /*let options = {
    // passing the client secret obtained from the server
    clientSecret: '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',
  };*/
  
  let options = {
    mode: 'payment' as const,
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {},
  };
  return (
    <div       style={{display: 'flex', flexDirection: 'column' ,alignItems: 'center', justifyContent: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',  // Ensures the image covers the entire div
      backgroundPosition: 'center',  // Centers the image in the div
      height: '100vh',  // Adjust height as needed
      width: '100%',  // Adjust width as needed
    }}>
      {stripePromise ? (<Elements stripe={stripePromise} options={options} ><NewForm/> </Elements> )  : ( <div>loading</div>) }
    </div>
  );
  


}


const mapStateToProps = (state) => ({
    auth: state.auth,
    register: state.register,
  });
//export default compose(connect(mapStateToProps))(Main);
export default compose(connect(mapStateToProps , {registerUserWithEmail} ))(Stripe);