
import React from 'react';
import { RgbaColorPicker} from "react-colorful";
import { RgbColorPicker } from 'react-colorful';
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import { ToolBoxContainer} from "./HeatMapTrackerStyles"
import styled from 'styled-components';
let Focus = styled.span`position: absolute;`
let ColorContainer = styled.div`z-index:600`;
export const HeatMapToolBox = (props: {blockId:number,onDelete(arg0: number): unknown, onChangeBackground(arg0:any ): unknown,
     onChangeTextColor(arg0:any ): unknown,onChangeHabitColor(arg0:any ): unknown , onChangedProperty(arg0:boolean): unknown;
}) => {

    
    let options =  ['Select','Delete Widget','Change Habit Color', 'Change Background' , 'Change Text Color'];
    const [showOptions , setShowOptions] = React.useState(false);
    //const [background, setBackground] = React.useState('');
    const [showBgColorPicker, setShowBgColorPicker] = React.useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = React.useState(false);
    const [showHabitColorPicker, setShowHabitColorPicker] = React.useState(false);
    function handleTextColorChange(e) {
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeTextColor(color);
        props.onChangedProperty(true);
    }
    function handleBgColorChange(e) {
        console.log(e)
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeBackground(color);
        props.onChangedProperty(true);
    }

    function handleHabitColorChange(e) {
        console.log(e)
        let color = "rgba( " + e.r +',' + e.g +',' + e.b + ")";
        props.onChangeHabitColor(color);
        props.onChangedProperty(true);
    }
    let onEvent =  (e: { target: { value: any; }; }) => {
        let eventType = e.target.value;
        if(eventType === 'Delete Widget'){
            props.onDelete(props.blockId);
            console.log('delete click')
        }
        else if(eventType === 'Change Background'){
            setShowBgColorPicker(true)
        }
        else if(eventType === 'Change Text Color'){
            setShowTextColorPicker(true)
        }
        else if(eventType === 'Change Habit Color'){
            setShowHabitColorPicker(true)
        }
    }

    function onOptionClick(){
        if(showOptions){
            setShowOptions(false);
            setShowBgColorPicker(false);
            setShowHabitColorPicker(false)
            setShowTextColorPicker(false)
        }
        else{
            setShowOptions(true);
        }
    }
    return <ToolBoxContainer>
            <div onClick={onOptionClick}><OptionBox/></div>
            {showOptions && <select id='imageToolBox' onChange={onEvent}>
                <optgroup/>
                {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
                ))}
            </select>
            }
            <Focus/>
            <ColorContainer>
            {
                showBgColorPicker ? <RgbaColorPicker onChange={handleBgColorChange} /> : ''
            }
            {
                showTextColorPicker ? <RgbaColorPicker onChange={handleTextColorChange} /> : ''
            }
            {
                showHabitColorPicker ? <RgbColorPicker onChange={handleHabitColorChange} /> : ''
            }
            </ColorContainer>
            <MoveBox/>
        </ToolBoxContainer>
}