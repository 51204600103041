//To do: 
// 1. get the the calendar data from the db instead of cookies??
//let calendarItems:any = localStorage.getItem('calendarItems'); 


function formatDate(originalDate){
  /*
  input: '2024-04-09T07:00:00.000Z'
  output: '2024-04-09'
  */
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
  const day = String(originalDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}
function getDatesBetween(start, end) {
  console.log('confirm: ' , new Date(start));
  for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
    arr.push(formatDate(new Date(dt)));
}
return arr;
}

let initialState = {}; // {'4-14-2023' : [event1 , ....]}

  // eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  console.log('in cal reducer: ' , type)
  let dates;
  let calendarItems = JSON.parse(localStorage.getItem('calendarItems'));//{'day':[events]}
  if(calendarItems === "undefined"){
    calendarItems = {};
  }
  else{
    calendarItems = JSON.parse(localStorage.getItem('calendarItems'));
  }
  switch (type) {
    case 'CalendarItemAdd'://maybe only add data to db instead of storing as a cookie here?
      // loop thru c
      console.log('adding to front end..., ', payload)
      console.log('cal items b4: ' , calendarItems);
      dates = getDatesBetween(payload['timeRange'][0] , payload['timeRange'][1]);
      for(let i = 0; i< dates.length;i++){
        console.log(i)
        let date = dates[i];
        if(date in calendarItems){
          calendarItems[date].push(payload);
        }
        else{
          calendarItems[date] = [payload];
        }
      }
      console.log('cal items after: ' , JSON.stringify(calendarItems) , calendarItems);
      localStorage.setItem('calendarItems',JSON.stringify(calendarItems));
      console.log("LOCAL STORAGE SET TO ", JSON.stringify(calendarItems));
      console.log("LOCAL STORAGE IS " , localStorage.getItem('calendarItems'))
      return {...calendarItems};
    case "CalendarItemEdit":
      dates = getDatesBetween(payload['timeRange'][0] , payload['timeRange'][1]);
      console.log('dates: ' , dates);
      for(let i = 0; i< dates.length;i++){
        
        let date = dates[i];
        
        if(date in calendarItems){
          
          for (let j = 0; j < calendarItems[date].length; j++) {
            console.log(2);
            let event = calendarItems[date][j];
            console.log(event['eventId'] ,payload['eventId'] )
            if(event['eventId'] === payload['eventId']){
              console.log("FOUND MATCH!")
              calendarItems[date][j] = payload;
              localStorage.setItem('calendarItems',JSON.stringify(calendarItems));
              return {...calendarItems};
            }
          }
        }
        else{
          calendarItems[date] = [payload];
        }
      }

    default:
      if(!localStorage.getItem('calendarItems')){
        localStorage.setItem('calendarItems' , JSON.stringify(initialState));
        return initialState
      }
      return JSON.parse(localStorage.getItem('calendarItems'))

  }
}
  