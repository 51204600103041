import React, { useState, useCallback, useEffect, useRef, createRef } from 'react';
import Collapsible from 'react-collapsible';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AddFileImg from './add_file.png'
import AddFolderImg from './add_folder.png'
import {rgbaToRgb , countStringsInTree , flattenFileTree  , isNoteFile, renameFileTree , addFileToTree} from './CollapsibleDirHelpers';
import {Container , DirContainer , AddButtonContainer , AddButton , Img , FileUI , Folder , AllFilesContainer , FileContainer , EditFileList , EditFileItem , FileHoverButton} from './TreeViewStyles'
import NoteFile  from './fileTypes';
import { saveLayout , loadLayouts } from '../../../../store/actions/userActions';

export const TreeView= ({ setActiveNote, state , saveLayout, blockId , background , width , height , textColor} ) => {
  /*
  props: 
    background: string
  

  FileTree: n-ary tree representing the names of the folders and files
            { bioNotes: {lecture1 , lecture2 , 
                        {MidTermPrep:
                          {temp , temp2} }
                        } , 
            csNotes: {}     }
  */

  let defaultFileData = '<p>Start writing greatness here....</p>';

  console.log('state.layout' , state.layout)
  let dummmyFileTree = useRef<Array<any>>([]);
  try{
    dummmyFileTree.current = state.layout[0].layout[blockId].blockData.fileTree;
  }
  catch{
    dummmyFileTree.current = state.layout[0][blockId].blockData.fileTree;
  }
  const [isOpen, setIsOpen] = useState(false);
  const [showRightClickMenu , setShowRightClickMenu] = useState(false);
  const [rightClickIndex, setRightClickIndex] = useState(-1);
  const disabledInputs = useRef<any[]>(Array(countStringsInTree(dummmyFileTree)).fill(true));
  const [hoverOverFileIndex, setHoveringOverFileIndex] = useState(-1);
  const fileRefs = useRef([]);
  const [deleteFileRender, setRenderTrigger] = useState(0);
  const handleRightClick = useCallback((e, index) => {
    //console.log("IN RIGHT CLICK" , e , index);
    e.preventDefault();
    setRightClickIndex(index);
    setShowRightClickMenu(true);
  }, []);





  let bg = rgbaToRgb(background)
  //console.log("BG IS " , bg)
  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };
  const addFileClick = (parentIndex , fileType) => {
    console.log("ADD FILE CLICK CALL, " , parentIndex , fileType)
    if(dummmyFileTree.current === undefined){
      dummmyFileTree.current = [];
    }
    
    //adding a file to root directory
    if(parentIndex === -1 ){
      console.log("\n\n\nIN -1 if statement!!!!!!")
      disabledInputs.current.push(false);
      console.log("dumm tree: ", dummmyFileTree)
      let temp = dummmyFileTree.current;
      if(fileType === 'file'){
        temp.push({name: 'untitled' , html:[]});
      }
      else{
        temp.push({'new folder':[]})
      }
      console.log("TEMP IS NOW:" , temp)
      dummmyFileTree.current = temp;
      setRenderTrigger(prev => prev + 1);
      try{
        state.layout[0][blockId].blockData.fileTree = dummmyFileTree.current;
      }
      catch{
        state.layout[0].layout[blockId].blockData.fileTree = dummmyFileTree.current;
      }
      saveLayout(state.auth.me.id  , state.layout[0] , null);
      
    }
    else{
      let [newTree , newFileIndex] = addFileToTree(dummmyFileTree , fileType, parentIndex)
      dummmyFileTree.current = newTree;
      console.log("IN non root dir", newFileIndex)
      //enable the new file
      disabledInputs.current.push(true);
      disabledInputs.current[newFileIndex + 1] = false;
      try{
        state.layout[0][blockId].blockData.fileTree = dummmyFileTree.current;
      }
      catch{
        state.layout[0].layout[blockId].blockData.fileTree = dummmyFileTree.current;
      }
      saveLayout(state.auth.me.id  , state.layout[0] , null);
    }
    
   
  }


  const handleClick = useCallback((e) => {
    e.preventDefault();
    alert('double clicked!')

  }, []);
  useEffect(() => {
    /*

      rename --> delete --> value is wrong but defaultValue is correct in fileRefs
                            dummmyfileTree is correct
      Called when a file is deleted, 
      All that happens is that we ensure the DOM matchs up to the data inside of the tree
      We do this by mapping the names of the fileRef Array to the tree
    */
    

  
      

    

    
    //console.log("\n\n\nIN USE EFFECT BEYATCH!!!!" , fileRefs.current , dummmyFileTree.current);
    
    //let flatTree = flattenFileTree(dummmyFileTree.current);
    let fileNames = [];
    let inputNames = []
    for(let i = 0;i<fileRefs.current.length;i++){
        if(fileRefs.current[i] === undefined || fileRefs.current[i] === null ){
          continue
        }
        
        if(inputNames.includes(fileRefs.current[i].id)){
          continue
        }
        inputNames.push(fileRefs.current[i].id);
        fileNames.push(fileRefs.current[i].value);
       
    } 




    let names = flattenFileTree(dummmyFileTree.current);
    console.log("BEFOre delete dummy , filerefs" ,names , fileNames );
    //make file refs match up to dummyfileTree? 

    //const updatedTree = renameFileTree(dummmyFileTree , fileNames);
    for(let i = 1;i<fileRefs.current.length;i++){
      if(fileRefs.current[i] === undefined || fileRefs.current[i] === null ){
        continue
      }
      if(isNoteFile(names[i - 1])){
        fileRefs.current[i].value = names[i - 1].name
      }
      else{
        fileRefs.current[i].value = names[i - 1]
      }
    } 
    console.log("AFTER: " , fileRefs.current )
    //dummmyFileTree.current = updatedTree;
  },[deleteFileRender]);

  function removeNode(path, subDirIndex , deleteIndex){
    /*
      path: list of ints representing the parent index's (starting from root)
      newNode: node to replace the old one with
      subDirIndex: the new node should be at index in it's subdir
      deleteIndex: the index of item to remove if couting all files in file tree
    */
   function deleteNode(path , dirNames , tree , ){
    //console.log('b4' , fileRefs , deleteIndex)
    if(path.length !== 0){
      let subDir = tree[path[0]][dirNames[0]]
      path.splice(0 ,  1)
      dirNames.splice(0 , 1)
      deleteNode(path , dirNames , subDir)
    }
    else{
      //console.log("DELETEING???" , tree , index)
      //
      //console.log(fileRefs)
      console.log('to delete: ' , tree , subDirIndex);
      let temp = fileRefs.current;
      temp.splice(deleteIndex  , 1)
      for(let i =0;i<temp.length;i++){
      
        try{
          //console.log( fileRefs.current[i].value, fileRefs.current[i].defaultValue)
          fileRefs.current[i].defaultValue = fileRefs.current[i].value;
          //console.log( fileRefs.current[i].value, fileRefs.current[i].defaultValue)
        }
        catch{
  
        }
  
      }
      fileRefs.current = [...temp];
      tree.splice(subDirIndex , 1);
      tree = [...tree];
      setRenderTrigger(prev => prev + 1)
    }
   }
    
    // build a list of strings to navigate to the new node 
    let fullPath = []
    let node = dummmyFileTree.current;//Object.assign({}, dummmyFileTree.current);;
    for(let i = 0;i< path.length;i++){
      console.log(node , path[i])
      //console.log(Object.keys(node[path[i]])[0])
      let parentDirName = Object.keys(node[path[i]])[0];
      console.log(parentDirName)
      fullPath.push(parentDirName)
      node = node[path[i]][parentDirName]
    }
    deleteNode(path , fullPath , dummmyFileTree.current)
    //console.log("FULL PATH: " , fullPath , path , dummmyFileTree.current)
  }
  
  function deleteFile(deleteIndex:number , path:Array<number | string> , count:number){
    /*
      index: item to delete
      path: list of parent dirs , starting from the root to the bottom
    */
    if(count > deleteIndex){
      return
    }
    //go to current subdir
    let subdir = JSON.parse(JSON.stringify(dummmyFileTree.current));
    
    //console.log('full subdir , path' , subdir , path);
    let j = 0;
    while(j < path.length){
      //console.log("SUBDIR: ",subdir , path[j])
      if(subdir instanceof Array){
        subdir = subdir[path[j]]
        //console.log('first if')
      }
      else{
        subdir = subdir[Object.keys(subdir)[0]][path[j]];
        
      }
      
      j += 1;
    }
    let sub_dir_iter = 0;
    
    while(count <=  deleteIndex){
      let item = null;
      // eslint-disable-next-line valid-typeof
      if(subdir instanceof Array){
        item = subdir[sub_dir_iter]
      }
      else{
        subdir = subdir[Object.keys(subdir)[0]];
        item = subdir[sub_dir_iter]
      }
      
      //if we get to desired element to be deleted
      if(count === deleteIndex){
        console.log("IN DELETE file if ")
        // figure out what this subdir should be
        let tempTree =  JSON.parse(JSON.stringify(dummmyFileTree.current));
        let t = 0;
        while(t < path.length){
          if(tempTree instanceof Array){
            tempTree = tempTree[path[t]]
          }
          else{
            tempTree = Object.keys(subdir)[0][path[j]];  
          }

          t+=1
        }
        subdir.splice(sub_dir_iter);
        removeNode(path , sub_dir_iter , deleteIndex);
        setRenderTrigger(prev => prev + 1);
        try{
          state.layout[0][blockId].blockData.fileTree = dummmyFileTree.current;
        }
        catch{
        state.layout[0].layout[blockId].blockData.fileTree = dummmyFileTree.current;
        }
        saveLayout(state.auth.me.id  , state.layout[0] , null);
       
        return;
      }

      if(isNoteFile(item)){
        count += 1
        sub_dir_iter += 1;
        continue
      }
      let filesInDir = countStringsInTree(item);//??
      if(filesInDir + count > deleteIndex){
        // if  the number of files in the dir + count > deleteIndex go in the dir and delete the file
        //console.log("Recursive call" , sub_dir_iter);
        path.push(sub_dir_iter);
        deleteFile(deleteIndex , path , count + 1)
        return
      }
      else{
        //console.log('in this else' , filesInDir , item)
        count += filesInDir;
        sub_dir_iter += 1;
      }
        
    }
  }

  function fileEditOptions(fileIndex , fileType){
    return (
      <EditFileList>
        <EditFileItem onClick={() => {
          const newState = Array(disabledInputs.current.length).fill(true);
          newState[fileIndex] = false;
          console.log('b4 bug:' , disabledInputs);
          disabledInputs.current = newState;
          fileRefs.current[fileIndex].focus();
          setRightClickIndex(-1);
          setShowRightClickMenu(false);
          console.log("IS EDITABLE: " , disabledInputs,  newState)}}>
            Rename</EditFileItem >
        <EditFileItem onClick={() => {setRightClickIndex(-1);deleteFile(fileIndex-1 , [] , 0)}}>Delete</EditFileItem >
        {fileType === "folder" ? <EditFileItem onClick={(e) => {setRightClickIndex(-1);setShowRightClickMenu(false);addFileClick(fileIndex-1 , 'file')}}>Add File</EditFileItem> : null}
        {fileType === "folder" ? <EditFileItem onClick={(e) => {setRightClickIndex(-1);setShowRightClickMenu(false);addFileClick(fileIndex-1 , 'folder')}}>Add Folder</EditFileItem> : null}
      </EditFileList>
    );
  };
  
  function genFileTreeDropDown(tree , depth , font , bg , tabIndex , index){
    // each file/folder is a input and is disabled until right clicked
    //console.log('curr: ' , disabledInputs.current)
    //console.log("\n\nSPAWNING FILES FROM" , tree , fileRefs)
    /*
    Delete lecture 1
    default    val
    lect2      lect1 --> val = delete

    rename

    default  value        
    lect1    lect1ajsakjsx -- > default = value

    */
   //console.log('tree in gen' , tree , fileRefs.current)
   

   // make file refs match up to the tree?
   // console.log("TREE IS " , tree , dummmyFileTree)
   console.log("TREE IS " , tree)
   if(tree === undefined){
    console.log("UNDEFINED TREE");
    return
   }
    return (
      <AllFilesContainer>
        {tree.map((item , j) => {
          //console.log(j)
          let currentIndex = index + 1;
          index += 1;

          if(isNoteFile(item)){
            console.log("is note file: " , item)
            let isReadOnly = disabledInputs.current[currentIndex];
            if(fileRefs.current[currentIndex]){
              
              if(fileRefs.current[currentIndex].value === ''){
                console.log('chnagine readonly to false!', currentIndex, fileRefs.current[currentIndex].value)
                //isReadOnly = false;
              }
            }
            return  <>
            <div>{rightClickIndex === currentIndex ? fileEditOptions(currentIndex , "file"): null}</div>
            <FileContainer>
              <FileUI id={Math.random().toString()} onChange={(e) => {
                fileRefs.current[currentIndex].defaultValue=e.target.value;
                fileRefs.current[currentIndex].value = e.target.value;
                dummmyFileTree.current = renameFileTree(dummmyFileTree, e.target.value , currentIndex - 1);
                try{
                  state.layout[0][blockId].blockData.fileTree = dummmyFileTree.current;
                }
                catch{
                  state.layout[0].layout[blockId].blockData.fileTree = dummmyFileTree.current;
                }
                saveLayout(state.auth.me.id  , state.layout[0] , null);
            }} ref={el => fileRefs.current[currentIndex] = el}  readOnly={isReadOnly} onMouseOver={(e) => {setHoveringOverFileIndex(currentIndex)}}
              onContextMenu={(e) => handleRightClick(e, currentIndex)} defaultValue={item.name} fontColor={font} backgroundColor={bg} depth={depth}
              onDoubleClick={(e) => {setActiveNote(currentIndex)}}/>
              <div>{hoverOverFileIndex === currentIndex ? <FileHoverButton onClick={(e) => { setHoveringOverFileIndex(-1);handleRightClick(e, currentIndex)} }>***</FileHoverButton> : null}</div>
             </FileContainer>
            </>
            
            
          }
          else{
            console.log('not note file' , item)
            // if the file is directory, display all files within it first
            let nextFolderIndex = currentIndex;

            //increase the index of the next file/directory at this level by the size of the current item
            index = currentIndex + countStringsInTree(item) - 1;
            //console.log("NEXT FOLDER INDED: " , nextFolderIndex , item);
            return <>
            <FileContainer>
              <div>{rightClickIndex === currentIndex ? fileEditOptions(currentIndex , "folder"): null}</div>
              
              <Folder 
              onMouseOver={(e) => {setHoveringOverFileIndex(currentIndex)}}
              onChange={(e) => {
                fileRefs.current[currentIndex].defaultValue=e.target.value;
                fileRefs.current[currentIndex].value = e.target.value;
                dummmyFileTree.current = renameFileTree(dummmyFileTree, e.target.value , currentIndex - 1);
                try{
                  state.layout[0][blockId].blockData.fileTree = dummmyFileTree.current;
                }
                catch{
                  state.layout[0].layout[blockId].blockData.fileTree = dummmyFileTree.current;
                }
                saveLayout(state.auth.me.id  , state.layout[0] , null);
              }} id={Math.random().toString()} ref={el => fileRefs.current[currentIndex] = el} onClick={(e ) => handleClick(e)} readOnly={disabledInputs.current[currentIndex]} 
              onContextMenu={(e) => handleRightClick(e, nextFolderIndex)} defaultValue={Object.keys(item)[0]} fontColor={font} backgroundColor={bg} depth={depth}/>
              <div>{hoverOverFileIndex === currentIndex ? <FileHoverButton onClick={(e) => { setHoveringOverFileIndex(-1);handleRightClick(e, nextFolderIndex)} }>***</FileHoverButton> : null}</div>
            </FileContainer>
            {genFileTreeDropDown( item[Object.keys(item)[0]] , depth + 1 , font , bg , tabIndex + 1 , nextFolderIndex) }
            </>

          }
  
        } )
      }
      </AllFilesContainer>
    );
  }

  return (
    <Container color={textColor} background={bg} width={width} height={height - 50}>
        <AddButtonContainer>
          <AddButton background={bg} onClick={() => addFileClick(-1,'file')}><Img src={AddFileImg}></Img></AddButton>
          <AddButton background={bg} onClick={() => addFileClick(-1,'folder')}><Img src={AddFolderImg}></Img></AddButton>
        </AddButtonContainer>
        {
          genFileTreeDropDown(dummmyFileTree.current , 0 , textColor , bg , 0 , 0)
        }
    </Container>
  );
};
const mapStateToProps = (state) => ({
  state: state,
});
export default compose(connect(    mapStateToProps , { saveLayout} ,null ))(TreeView);

