import styled, { css } from 'styled-components';
import { RgbaColorPicker} from "react-colorful";
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import React, { useState, useMemo } from 'react';

export const NewsToolBox = (props: {backgroundColor:string, textColor:string, blockId:number,
    onDelete(arg0: number): unknown, onChangeBG(arg0: string): unknown, onChangeTextColor(arg0: string): unknown , onChangedProperty(arg0:boolean): unknown
  }) => {
  
    let [showColorPickerBG, setShowColorPickerBG] = useState(false);
    let [showColorPickerText, setShowColorPickerText] = useState(false);
    const [showOptions , setShowOptions] = useState(false);
    let options =  ['Select', 'Change Background Color','Change Text Color','Delete'];
    let onEvent =  (e: { target: { value: any; }; }) => {
    let eventType = e.target.value;
      if(eventType === 'Delete'){
          console.log('TO DO somehow delete image');
          props.onDelete(props.blockId);
      }
      else if(eventType === 'Change Background Color'){
        setShowColorPickerBG(true);
      }
      else if(eventType === 'Change Text Color'){
        setShowColorPickerText(true);
      }
    }
    function handleBGChange(e) {
      console.log(e)
      let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
      props.onChangeBG(color);
      props.onChangedProperty(true);
    }
    function handleTextColorChange(e) {
      console.log(e)
      let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
      props.onChangeTextColor(color);
      props.onChangedProperty(true);
    }
    function onOptionClick(){
      if(showOptions){
          setShowOptions(false);
          setShowColorPickerBG(false);
          setShowColorPickerText(false);
      }
      else{
          setShowOptions(true);
      }
  }
  // @ts-ignore
  return <>
          <div onClick={onOptionClick}><OptionBox/></div>
          {showOptions &&           
          <select id='NewsToolBox' onChange={onEvent}>
              {options.map((option, index) => (
              <option key={index} value={option}>
                  {option}
              </option>
              ))}
          </select>}

          { showColorPickerBG && <div>
                <RgbaColorPicker onChange={handleBGChange} />
                <button onClick={() => {console.log("EXIT BG COLOR CLICK");setShowColorPickerBG(false)}}>Set Color</button>
              </div>
          }
          { showColorPickerText && <div>
            <RgbaColorPicker onChange={handleTextColorChange} />
                <button onClick={() => {console.log("EXIT BG COLOR CLICK");setShowColorPickerText(false)}}>Set Color</button>
              </div>
          }
          <MoveBox/>
      </>
}