//import { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
//import { useDebouncedCallback } from "use-debounce";
import { debounce } from "lodash";
import "./styles.css";
import React, { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from 'react-redux';
import { noteUpdate } from "../../../../store/actions/noteActions";
import styled from "styled-components";
import {getNoteData , updateFile} from "./CollapsibleDirHelpers";
import Delta from "quill-delta";
Quill.register("modules/imageResize", ImageResize);
let modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
    [{ color: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
};
let formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
];

let Container = styled.div<{color:string , height:number}>`
  position: relative;
  z-index: 1;
  .ql-fill{
    fill:${props => props.color};
  }
  .ql-snow{
    .ql-stroke{
      stroke:${props => props.color};
    }
  }
  .ql-toolbar{
    height:60px;
    .ql-snow{
      height:60px;
    }
  }
  .ql-picker-label{
    color:${props => props.color};
    
  }
  .ql-editor{
    height:${props => props.height - 60}px;
    overflow:auto;
  }
`

export const Editor = ({ activeNote , state , blockId , height, toolBarColor,noteUpdate} ) => {
  
  console.log('data got back got back : ',getNoteData(state.layout[0].layout[blockId].blockData.fileTree , activeNote)) 
  const [value, setValue] = useState(new Delta( getNoteData(state.layout[0].layout[blockId].blockData.fileTree , activeNote)));
  const [deltas , setDeltas] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  console.log('\n\n\n\ninit value', deltas );
  function submit(e) {
      e.preventDefault();
      console.log(value);
  }
  const debouncedSave = useCallback(
    debounce((documentData , newDeltas) => {
      let oldTree = state.layout[0].layout[blockId].blockData.fileTree;
      let newfileTree;
      if(activeNote === -1){
        alert('pick or make a new file before editing');
      }
      try{
        newfileTree = updateFile(state.layout[0][blockId].blockData.fileTree , newDeltas, activeNote)
        console.log("FILE TREE IS: " , newfileTree)
  
      }
      catch{
        try{
        //this.globalState.layout[0][this.blockId].blockData.html = newfileTree;
        //this.saveLayout(this.globalState.auth.me.id  , this.globalState.layout[0] , null);
        newfileTree = updateFile(state.layout[0].layout[blockId].blockData.fileTree , newDeltas , activeNote);
        console.log("FILE TREE,deltas IS: " , newfileTree , newDeltas)
        console.log("B4" , state.layout[0].layout[blockId].blockData.fileTree)
        state.layout[0].layout[blockId].blockData.fileTree = newfileTree;//BUG HERE
        console.log("after" , state.layout[0].layout[blockId].blockData.fileTree)


        // call reducer here
        //this.saveLayout(state.auth.me.id  ,state.layout[0] , null);
        //onsole.log('done w save layout' , this.saveLayout)
        }
        catch{
          console.log('error')
        }
      }
      // send deltas to server
      console.log("oldtree , old tree" ,oldTree,newfileTree )
      noteUpdate(state.auth.me.id  , {deltas: newDeltas , oldTree: oldTree ,tree: newfileTree , blockId: blockId , activeNote: activeNote} , null)
      // make deltas empty 
      setDeltas([])

     
    }, 3000), // Adjust debounce delay as needed
    []
  );
  function handleChange(content, delta, source, editor) {

      
      if(isFirstRender){
        setIsFirstRender(false);
        console.log('\nNO CHANGE;]', content)
        return
      }
      else{
        console.log('important=1' , content , editor.getHTML())
      }
      // update the frontend
      console.log("HANDLE CHANGE CALLED" , content, delta, source, editor)
      console.log('*****' , delta , '****')
      let docContent = editor.getContents();
      setValue(docContent);

      // save the new delta
      console.log('curr deltas , new delta' , deltas , delta)
      setDeltas(prevDeltas => [...prevDeltas, delta]);
      //after n seconds with no changes send deltas to backend
      debouncedSave(docContent, [...deltas, delta]);

  }

  return (
    <Container color={toolBarColor} height={height}>
        <ReactQuill style={{height:'auto'}} modules={modules} formats={formats} theme="snow" onKeyPress={(e) => {setIsFirstRender(false)}} defaultValue={value} onChange={handleChange} />
    </Container>
  );
}
const mapStateToProps = (state) => ({
  state: state,
});
export default compose(connect(    mapStateToProps , { noteUpdate} ,null ))(Editor);

