import axios from 'axios'


let base_url = process.env.REACT_APP_BASE_URL;
if(process.env.NODE_ENV !== "production"){
  base_url = "https://localhost:80"
}
export const attachTokenToHeaders = (getState) => {
    const token = getState().auth.token;
  
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
  
    if (token) {
      config.headers['x-auth-token'] = token;
    }
  
    return config;
  };
export const hideTutorial = (id   , history) => async (dispatch, getState) => {
    console.log("**********IN hide tut item" , id, history, '*********');
    try{
        console.log('loading...')
        const options = attachTokenToHeaders(getState);
        console.log('loading1...')
        const response = await axios.put(base_url + `/showTutorial/${id}`, {}, options);
        console.log("RESPONSE save calendar item " , response);
        dispatch({
          type: "hideTutorial",
          payload: false,
        });
    }
    catch{
        console.log("error in calendar save action")
    }
  };