
import React, { useState } from "react";
import styled from "styled-components";
import moment, { Moment } from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { addCalendarItem } from '../../../../store/actions/calendarActions';
import { useDispatch } from 'react-redux';
//import { addCalendarItem } from '../../store/actions/calendarActions';
// @ts-ignore
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import {NumberInput,Label , HabitEntryDetail} from "./HabitStyle";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
//import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { isBoolean } from "lodash";
import { addHabitEntry } from "../../../../store/actions/HabitActions";
import { HabitNameInput } from './HeatMapTrackerStyles';


const StyledButton = styled.button<{active:boolean , backgroundColor:string}>`    
    background-color: ${({ active , backgroundColor}) => (active ? backgroundColor :  'grey')};
`
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
export const AddHabitPopUp = (props: { textColor:string ,width:number ,blockId:number, height:number , 
    color:string , isBoolHabit:boolean ,date:string,habitName:string , state:any , 
    entryData: number | boolean , showWindow , addHabitEntry}) => {

    
    /*
    boolean Habit: 

    Habit Name
    Date: May 10th 2024
    Streak {N}       Complete

    
    Number Habit

    Habit Name
    Date: May 10th 2024
    Quanity: input
    Streak {N}       Save
    */ 
    const dispatch = useDispatch();
    const TextInput =  HabitNameInput;
    let [habitEntry , setHabitEntry] = useState<number | boolean>(props.entryData);
    let [showNumberInputWarning , setShowNumberInputWarning] = useState(false);
    console.log(props)

    async function onSave(dataOnLeave: number | boolean){
        console.log(dataOnLeave , props.entryData)
        if(dataOnLeave === props.entryData){
            props.showWindow(false)
            return
        }
        console.log("calling dispatch function....")
        //dispatch(props.addHabitEntry(props.state.auth.me.id , {'date':props.date , 'value': dataOnLeave , 'blockId':props.blockId } , {} ));  
         
        await updateBlock(props.state.layout ,props.state.auth ,props.blockId , {value:dataOnLeave} )
        props.showWindow(false)    
    }
    
    async function updateBlock(layout , auth, blockId , data){
        //update redux state
        // to do make a actual action and iter thru all keys in data
        console.log("IN UPDATE habit: " , data , layout)
        if('habitEntrys' in layout[0].layout[parseInt(blockId)].blockData){
            console.log('in if')
            layout[0].layout[parseInt(blockId)].blockData.habitEntrys[props.date] = data.value
        }
        else{
            console.log('in else')
            layout[0].layout[parseInt(blockId)].blockData.habitEntrys = {};
            layout[0].layout[parseInt(blockId)].blockData.habitEntrys[props.date] = data.value
        }
        console.log("\n\non yt video save: " , layout)
        props.addHabitEntry(auth.me.id  , {value:data.value , blockId:blockId , date:props.date} , null);
    }
    function handleHabitEntryChange(e){
        let input = e.target.value;
        try{
            let numberInput = parseInt(input, 10);
            setHabitEntry(numberInput);
            setShowNumberInputWarning(false);
        }
        catch{
            setShowNumberInputWarning(false);
        }
        setHabitEntry(e.target.value);
    }
    return (
            <div>
            {props.isBoolHabit &&  <HabitEntryDetail width={props.width}  height={props.height} backgroundColor="white">
                <Label>{props.habitName}</Label>
                <Label>{props.date}</Label>
                <button onClick={() => props.showWindow(false)}>X</button>
                <button onClick={() => onSave(!props.entryData!)}>{props.entryData ? <div>Undo</div>: <div>Complete</div>}</button>
                </ HabitEntryDetail>
            }
            {!props.isBoolHabit &&  <HabitEntryDetail width={props.width}  height={props.height} backgroundColor="white">
                <Label>{props.habitName}</Label>
                <Label>Date: {props.date}</Label>
                
                <NumberInput onChange={handleHabitEntryChange} fontColor={props.textColor} backgroundColor={props.color} placeholder="Type A number Here"></NumberInput>
                <button onClick={() => props.showWindow(false)}>X</button>
                <button onClick={() => onSave(habitEntry)}>{<div>Save</div>}</button>
                {showNumberInputWarning && <div>Put Number's in only</div>}
                </ HabitEntryDetail>
            }
            </div>
        )
}
const mapStateToProps = (state) => ({
    state:state
  });
export default compose(connect(    mapStateToProps , {addHabitEntry} ,null ))(AddHabitPopUp);
