
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from "styled-components";
import compliColor from 'complimentary-color';
import axios from 'axios';
//import contrast from 'contrast';
let MessageBoxContainer = styled.div<{  $backGroundColor:string, $width:number,$height:number}>`
    width:${props => props.$width}px;
    height:${props => props.$height}px;
    background-color:${props => props.$backGroundColor};
    z-index:101; 
    position: absolute;
    bottom:20px;
    right:0;
    border:1px solid black;
    `

let TextAeraContainer = styled.div `position: relative;`
let ReciepentButton = styled.div`
        
        `
let ComposeDiv = styled.div<{$textColor?:string}>`
    border-bottom: 1px solid ${props => props.$textColor || 'black' };
    color: ${props => props.$textColor || 'black' }; 
    display: flex;
    flex-wrap: wrap;
`
let BottomComposeDiv = styled.div<{$textColor?:string}>`
    color: ${props => props.$textColor || 'black' }; 
`
let TextInput = styled.textarea<{ $height:number  , $backGroundColor:string, $textColor:string , $width:number}>`
    height:${props => props.$height}px;
    border-bottom: 1px solid ${props => props.$textColor || 'black' };
    width:${props => props.$width}px;
    background-color:${props => props.$backGroundColor};


    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;

    padding: 0;
    transition: background 0s ease-out 0s;
    color: ${props => props.$textColor};
    display: initial;
    outline: none;
    font-size: 15px;
    &:focus {
        background-size: 100% 2px, 100% 1px;
        outline: 0 none;
        transition-duration: 0.3s;
      }
`
let SendButton = styled.button<{$backGroundColor:string , $textColor:string}>`
    width:65px;
    height:35px;
    background-color: #EA4C89;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: ${props => props.$textColor };
    cursor: pointer;
    display: inline-block;
    box-border:1px
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    background-color: ${props => props.$backGroundColor};
    border:1px solid black;
    &:hover,
    &:focus {
        background-color: ${props => props.$backGroundColor};
    }
`
function rgbaToRgb(rgba:string) {
    // Extracting the values from the string
    const rgbaValues = rgba.match(/\d+/g);

    // Extracting RGB values
    const rgbValues = rgbaValues.slice(0, 3);

    // Constructing the RGB string
    const rgbString = `rgb(${rgbValues.join(',')})`;

    return rgbString;
}

export const SendMessageBox = (props: { setShowMessageBox: any , state:any,textColor:string , width:number,height:number , backGroundColor:string , secondaryColor:string})=>{

    let [recieverInput , setRecieverInput] = useState('To');
    let [subject , setSubject] = useState('Subject');
    let [message , setMessage] = useState('Content');
    let [recipients , setRecipients] = useState([]);
    function handleSubjectChange(e){
        setSubject(e.target.value);
    }
    function handleRecieverChange(e){
        let reciversString = e.target.value;
        let lastChar = reciversString.charAt(reciversString.length - 1);
        if(lastChar === ' ' || lastChar === '\n'){
            let newRecipents = recipients;
            newRecipents.push(e.target.value);
            setRecipients(newRecipents);
            e.target.value = ''

        }
        console.log(e.target.value);
        setRecieverInput(e.target.value)
    }
    function handleContentChange(e){
        setMessage(e.target.value);
    }

    async function sendMessage(){

        console.log("CALLING API")
        props.setShowMessageBox(false);
        const token = props.state.auth.token;
        const config = {
          headers: {
            'Content-type': 'application/json',
          },
        };
        if (token) {
          config.headers['x-auth-token'] = token;
        }



        let messageData = { token: props.state.auth.token,
                            message:message,email : props.state.auth.email ,
                            refreshToken: props.state.auth.me.googleRefreshToken,
                            text: message, 
                            reciever: recipients,
                            subject: subject}
        // make loading symbol pop up here

        //send the message and then hide the message box
        const sendResponse = await axios.post('https://localhost:80/mail/send/', messageData , config);
       
    }
    /*
    usestate hold the To String that the user types
    if last char is ' ' or \n then add it the another use state of recipentens and pop the last email in the to string
    */
    async function removeRecipent(emailToRemove:string){
        console.log('clicked')
        let i = recipients.indexOf(emailToRemove);
        let tempRecievers = recipients;
        tempRecievers.splice(i,1);
        setRecipients([...tempRecievers]);
        console.log('removed...')
    }
    return <MessageBoxContainer $height={props.height} $width={props.width} $backGroundColor={rgbaToRgb(props.backGroundColor)}>
        <ComposeDiv $textColor={props.textColor}>
            {
                recipients.map((email) => <ReciepentButton>
                    {email} <button onClick={() => removeRecipent(email)}>X</button>
                    </ReciepentButton>)
            }
            <TextInput  placeholder="To" onChange={handleRecieverChange} $textColor={props.textColor} $width={props.width} $backGroundColor={rgbaToRgb(props.backGroundColor)} $height={35} >
            </TextInput>
        </ComposeDiv>
        <ComposeDiv $textColor={props.textColor}>
            <TextInput  placeholder="Subject" onChange={handleSubjectChange} $textColor={props.textColor} $width={props.width} $backGroundColor={rgbaToRgb(props.backGroundColor)} $height={35}/>
        </ComposeDiv>
        <ComposeDiv $textColor={props.textColor}>
            <TextInput  placeholder="Message" onChange={handleContentChange} $textColor={props.textColor} $width={props.width} $backGroundColor={rgbaToRgb(props.backGroundColor)} $height={props.height -120}/>
        </ComposeDiv>
        <BottomComposeDiv $textColor={props.textColor}>
                <SendButton onClick={sendMessage}$textColor={props.textColor} $backGroundColor={props.secondaryColor} defaultValue={'Send'}>Send</SendButton>
                <SendButton onClick={props.setShowMessageBox} $textColor={props.textColor} $backGroundColor={props.secondaryColor}>Cancel</SendButton>
        </BottomComposeDiv>
        
    </MessageBoxContainer>
}