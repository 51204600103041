import { initializeApp } from "firebase/app";
import { getFirestore,getDocs,collection,where,query,orderBy} from "firebase/firestore";
import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { RgbaColorPicker} from "react-colorful";
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import { ArticleView } from "./ArticleView";

import {NewsContainer , ToolBoxContainer} from "./NewsStyles";
import { updateStyles } from '../helpers';

import { NewsToolBox } from "./NewsToolBox";
import { saveStyle } from "../../../../store/actions/blockActions";
import { compose } from "redux";
import { connect } from 'react-redux';





const loadData = async() => {
  const firebaseConfig = {
    apiKey: "AIzaSyCaSGIuBdg84PK4cg5cvCvDuNS17OoH3qM",
    authDomain: "foxcnnv3.firebaseapp.com",
    projectId: "foxcnnv3",
    storageBucket: "foxcnnv3.appspot.com",
    messagingSenderId: "105283761590",
    appId: "1:105283761590:web:140dafd05afd7d2c6a3d17",
    measurementId: "G-3Z1M00CNET"
  };
  //to do add sentiment analysis on news to see if good!
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const articlesRef = collection(db, "articles");
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  var yesterday = new Date();
  let todayRef = query(articlesRef,where("year", "==", yyyy),where("month", "==", mm),where("day", "==", dd));
  yesterday.setDate(yesterday.getDate() - 1);

  var yesterday_dd = yesterday.getDate();
  var yesterday_mm = yesterday.getMonth() + 1; 
  var yesterday_yyyy = yesterday.getFullYear();
  let yesterdayRef = query(articlesRef,where("year", "==", yesterday_yyyy),where("month", "==", yesterday_mm),where("day", "==", yesterday_dd));
  let articles: any[] = [];
  let querySnapshot = await getDocs(todayRef);
  querySnapshot.forEach((doc) => {
      articles.push(doc.data());
  });


  querySnapshot = await getDocs(yesterdayRef);
  querySnapshot.forEach((doc) => {
      articles.push(doc.data());
  });
  console.log('articles: ' , articles);
  articles.sort((a,b) => a.headline_number - b.headline_number)
  return articles;
  }


  function getDefaultParams(state , blockId){
    let params = {bg:'rgba(255,255,255,.5)' , textColor:'black' }
    let layout = state.layout[0].layout;
    console.log('state in default params: ', state)
    try{
      if(layout[blockId].blockData.widgetStyle.bg && layout[blockId].blockData.widgetStyle.textColor
       ){
       params.bg = layout[blockId].blockData.widgetStyle.bg;
       params.textColor = layout[blockId].blockData.widgetStyle.textColor;
       return params
      }
    }
    catch{
      return params;
    }
    return params
}
export const NewsBlock = (blockWidth:number,blockHeight:number , blockId:number, onDelete: (arg0: number,) => unknown ,state, saveStyle) => {
  let params = getDefaultParams(state , blockId);
  const [data, setData] = useState([{}]);
  const [error, setError] = useState(0);
  const [isPending, setIsPending] = useState(true);
  let [hovering, setHovering] = useState(false);
  let [background,setBG] = useState(params.bg);
  let [textColor,setTextColor] = useState(params.textColor);
  let [changedProperty , setChangedProperty] = useState(false); 
  function MouseOver(event: any) {
      setHovering(true);
  }
  function MouseOut(event: { target: { id: string; className: string; }; }){
      //FUnction is being called when toolbox opens up even though it's a child of the 
      // containing div which has a mouse out event
      let colorPickerClassNames = ["react-colorful__pointer react-colorful__saturation-pointer","react-colorful__pointer react-colorful__hue-pointer","react-colorful__interactive"]
      if(event.target.id !== "NewsToolBox" && !colorPickerClassNames.includes(event.target.className)){
          console.log("out of vid cause: !",typeof event.target , event.target, event.target.id);
          setHovering(false);
          if(changedProperty){
            let updatedStyle = {bg: background, textColor: textColor}
            updateStyles(state.layout , state.auth , blockId , updatedStyle , saveStyle);
            setChangedProperty(false);
          }
      }
  }
  useMemo(() => {
    const fetchData = async () => {
      try {
        console.log("\n\n\nLOADING DATA");
        const result = await loadData();
        console.log("\n\n\nfin LOADING DATA" , result);
        setData(result);
      } catch (err) {
        console.log("ERROR: ", err)
        setError(1);
      } finally {
        setIsPending(false);
      }
    };

    fetchData();
  }, []);
  function stopPropagation(event: {target: any;stopPropagation: () => void; }){
    if(event.target.id !== "MoveBox"){
      event.stopPropagation();
    }
  };
  // @ts-ignore
  if (isPending) return <div max-width={2000} max-height={100}>..Loading</div>;
  //if (error) return `Something went wrong: `;
  if (data && typeof window !== "undefined") {
    //console.log(window.innerHeight) /// wrong
    let blockPixels = blockWidth * blockHeight;

    // non picture block aera ~= 270 * 100 = 2700 pixels
    //num cols = block width // 250
    //num rows = blockHeight // 100
    console.log('w,h', blockWidth,blockHeight)
    let articleCols = Math.floor(blockWidth / 250);
    let articleRows = Math.floor(blockHeight / 100);
    let numArticlesShown = articleCols * articleRows;
    let articlesToShow = data.slice(0,numArticlesShown);

    // @ts-ignore
    return (
      // @ts-ignore
        <div onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut}>
          <NewsContainer $textColor={textColor} $backgroundColor={background} $height={blockHeight - 30} $width={blockWidth - 30}> 
            <ToolBoxContainer>
                {hovering && <NewsToolBox onChangedProperty={setChangedProperty} backgroundColor={background} textColor={textColor} blockId={blockId} onChangeTextColor={setTextColor} onDelete={onDelete} onChangeBG={setBG}></NewsToolBox>}
            </ToolBoxContainer>
            {articlesToShow?.map((article: any) => (ArticleView(article, textColor)))} 
          </NewsContainer>
      </div>)
  }

  return null;
};


const mapStateToProps = (state) => ({
  state: state,
});
export default compose(connect(    mapStateToProps , {saveStyle , } ,null ))(NewsBlock);
