import styled from "styled-components";



const HeatMapContainer = styled.div<{$textColor?:string, $height: number;$width: number;backgroundColor:string; }>`
  height: ${props => props.$height}px;
  width: ${props => props.$width}px;
  overflow:hidden;
  color: ${props => props.$textColor || "black"};
  background-color: ${props => props.backgroundColor};
  border: 1px solid black; /* Just for visual reference */;
  z-index:-100;
  position:absolute;
`;

const HabitNameContainer = styled.div<{$height: number;$width: number;}>`
    height: ${props => props.$height}px;
    width: ${props => props.$width}px;
    z-index:-1;
    position: absolute;
    top: 0;
    left: 0;
`
const MonthLabelContainer = styled.div<{$height: number;$width: number;}> `
    height: ${props => props.$height}px;
    width: ${props => props.$width}px;
    position: absolute;
    top: 45px;
    right: 0px;
    z-index:-1;
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
`
const DayLabelContainer = styled.div<{$height: number;$width: number;}>`
    height: ${props => props.$height}px;
    width: ${props => props.$width}px;
    bottom: 0px;
    left: 0;
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index:-1;
`
const DayLabel = styled.div<{fontColor:string}>`
    font-size: 16px;
    color: ${props => props.fontColor};
    z-index:-1;
`
const PlotContainer = styled.div<{$height: number;$width: number;}>`
    height: ${props => props.$height}px;
    width: ${props => props.$width}px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index:-1;
    input::placeholder {
        color: red;
    }
    `
const HabitNameInput = styled.input<{fontColor:string , backgroundColor:string}> `
	width: 90%;
	padding: 0x 30px 30px 30pxpx;
    height:40px;
	border-radius: 2px;
    border-color: ${props => props.fontColor };
	background-color: ${props => props.backgroundColor};
    color: ${props => props.fontColor };
    display: inline-block;
    font-size:35px;
    position: absolute;
    top:5px;
    left:50px;
    z-index:-1;
    border-width:0px;
    border-bottom:solid;
    ::placeholder {
        color: red;
      }
`

let HoverOverRectPopUp = styled.div<{x:number , y:number, textColor:string , background:string; }>`
    left: ${props => props.x}px;
    top:  ${props => props.y}px;
    position:absolute;
    border: 1px solid ${props => props.textColor };
    color: ${props => props.textColor };
    background:  ${props => props.background};
`

let ToolBoxContainer = styled.div`position: absolute;
    pointer-events:auto;`
export { HoverOverRectPopUp , PlotContainer , DayLabelContainer , MonthLabelContainer , HabitNameContainer , HeatMapContainer , DayLabel , ToolBoxContainer , HabitNameInput};