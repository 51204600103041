//To do: 
// 1. get the the calendar data from the db instead of cookies??
//let habitEntrys:any = localStorage.getItem('habitEntrys'); 


  
let initialState = {}; // {'Habit Name':'Hours of Sleep', isBool:false, entrys: {'3/6/24' : 8.5} }

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
    switch (type) {
        case 'hideTutorial'://maybe only add data to db instead of storing as a cookie here?
            localStorage.setItem('showTutorial' , JSON.stringify({show:false}));
            return {show:false}
        // loop thru c
        console.log('adding to front end..., ', payload)
        default:
            if(!localStorage.getItem('showTutorial')){
                console.log('in this if' ,localStorage.getItem('showTutorial') )
                localStorage.setItem('showTutorial' , JSON.stringify({show:true}));
                return {show:true}
            }
            
            return JSON.parse(localStorage.getItem('showTutorial'))

    }
  }
    