import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
function repairBase64String(inputString) {
    let replacedString = inputString.replace(/-/g, '+');
  
    // Replace all instances of '_' with '/'
    replacedString = replacedString.replace(/_/g, '/');
    return replacedString;
    }

const Container = styled.div<{height: number;width: number; }>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    float:left;
`;
const FilePreview = ({ base64String, mimeType  }) => {
    console.log("PREVIEW CALLED")
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
    // Function to convert base64 string to Blob and create an object URL
    const createObjectUrl = (base64, mimeType) => {
        // Decode base64 string

        //console.log('base64 string: ' , base64)
        base64 = repairBase64String(base64)
        const byteCharacters = atob(base64);
        const byteArrays = [];

        // Convert each character to a byte
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        // Create a Blob from the byte array
        const blob = new Blob(byteArrays, { type: mimeType });
        return URL.createObjectURL(blob);
    };

    // Create and set file URL
    const url = createObjectUrl(base64String, mimeType);
    setFileUrl(url);

    // Clean up the object URL when the component unmounts
    return () => URL.revokeObjectURL(url);
    }, [base64String, mimeType]);

    return (
    <Container width={260} height={260} >
        {mimeType === 'application/pdf' ? (<iframe src={fileUrl} title="PDF Preview" width="100%" height="100%" />) : ''}
        { mimeType === 'image/png' ? (<img src={fileUrl} alt="Image Preview" width="100%" />) : <p>Unsupported file type</p>}
    </Container>
    );
};

export default FilePreview;