export default function listenForOutsideClicks(
    listening,
    setListening,
    menuRef,
    setIsOpen
  ) {
    return () => {
      if (listening) {console.log('return 1');return;}
      if (!menuRef.current){ console.log('return 2');return;}
      setListening(true);
      [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          const cur = menuRef.current;//top div
          if(!cur){return};
          const node = evt.target;//node thats clicked
          //console.log(cur , node.classList)
          //if (node instanceof Element) { 
          //  if(Array.from(node.classList).includes("Option")){return};
          //}
          
          if(cur.contains(node)){return};
          console.log("SHOW OPTIONS SET TO FALSE IN LISTENER")
          setIsOpen(false);
        });
      });
    };
  }