import React from "react";
import { FunctionComponent, useState, useEffect,useRef,createRef, Children, Component, JSX } from "react";
import DropDrag from "./grid";
import { RgbaColorPicker } from "react-colorful";
import { Sidebar, Menu, MenuItem , SubMenu } from 'react-pro-sidebar';
import {menuItemStyles , SideBarContainter , NoWidgetViewColor , NoWidgetViewLink , BurgerButton , BurgerBar , SubMemuSumDesc} from "./MainStyling"
import axios from 'axios';
import _ from "lodash";
import { Link } from 'react-router-dom';
import Tutorial from "./tutorial";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { saveLayout } from '../../store/actions/userActions';
import { logOutUser } from '../../store/actions/authActions';
import { deleteUser } from '../../store/actions/authActions';
import { hideTutorial } from "../../store/actions/tutorialActions";
function getDefualtNewBlock(blockType){
  //no
  let newBlock =  {
    x: (_.random(2, 40) * 2) % 12,
    y: (_.random(2, 96) * 2) % 12,
    w: 20,
    h: 20,
    i: Math.random().toString(),
    static: false,
    blockType:blockType,
    blockData: {}
  }
  if(blockType === "Image"){
    newBlock.blockData = {link: "https://i.pinimg.com/736x/c3/9e/00/c39e00a600cac87fc02becba65361d02.jpg"}
  }
  if(blockType === "Youtube"){
    newBlock.blockData = {link: "https://www.youtube.com/watch?v=yKTND_ikxlc"}
  }
  if(blockType === "CheckHabit"){
    newBlock.w = 40;
    newBlock.blockData = {habitName:"habit" , booleanHabit:true , habitEntrys: {}}
  }
  if(blockType === "NumberHabit"){
    newBlock.w = 40;
    newBlock.blockData = {habitName:"habit" , booleanHabit:false , habitEntrys: {}}
  }
  return newBlock;
}

function initBg(state){
  console.log('init bg', state)
  if(localStorage.getItem('background')){
    let bg = localStorage.getItem('background');
    if(bg[0] === "\\" && bg[-1] === "\\"){
      bg = bg.slice(2,-2);
    }
    bg = bg.replace("\"" , "");
    bg = bg.replace("\"" , "");
    console.log("BG IS : ", bg)
    return bg
  }
  try{
    return state.layout[0].background
  }catch{
    return "rgba(20,20,20,.5)"
  }
}

function initLayout(state){
  
  try{
    return state.layout[0].layout;
  }catch{
    return []
  }
  
}
const Main: FunctionComponent<any> = ( {auth,state,logOutUser,saveLayout,hideTutorial, deleteUser , history}) =>  {
  const childRef = createRef();
  useEffect(()=>{

    // print the ref to the console
    console.log(childRef);       

})
  console.log('state is ' , state)
  let [background, setBackground] = useState<any>( () => initBg(state));
  console.log("BG AFTER SET IS " , background)
  let [ gridNumber , setGridNumber] = useState<number>(0);
  const [showSideBar, setShowSideBar] = useState(false);
  const [imageLinkInput, setImageLinkInput] = useState("");
  let [userName, setUserName] = useState('');
  let [name, setName] = useState('');
  let [password, setPassword] = useState('');
  let [ showHabitPickerPopUp,setShowHabitPickerPopUp ] = useState(false);
  const [showTutorial , setShowTutorial] = useState(state.showTutorial.show)
  let [gridProps,setGridProps] = useState({
    className: "layout",
    rowHeight: 2,
    width:1200,
    onLayoutChange: (layout: any, layouts: any) => {},
    cols: { lg: 96 },
    breakpoints: { lg: 36000},
    containerPadding: [0, 0],
    blockTypes:[],
    background:background,
    auth:auth,
    saveLayout:saveLayout,
    state:state,
    gridLayout: initLayout(state)
  });
  function handleUserNameChange(e) {
      setUserName(e.target.value);
  }
  function handleNameChange(e) {
      setName(e.target.value);
  }
  function handlePasswordChange(e) {
      setPassword(e.target.value);
  }
  let addWidget =  (widgetType:string) => {
    let newGridProps = gridProps;
    newGridProps.blockTypes.push(widgetType);

    if(!newGridProps.gridLayout){
      newGridProps.gridLayout = [];
      console.log("SET TO EMPTY ???\n\n\n")
    }
    try{
      newGridProps.gridLayout.push(getDefualtNewBlock(widgetType));
      setGridProps(newGridProps);
      saveLayout( auth.me.id,{ layout : newGridProps.gridLayout , background: background}  , history);
      console.log('b4 change layout call' , childRef)
      // @ts-ignore
      childRef.current.changeLayout();
      setShowSideBar(false);
    }
    catch{
      setShowSideBar(false);
    }

  }
  async function updateBlock(layout , auth, blockId , data){
    //update redux state
    // to do make a actual action and iter thru all keys in data
    if(layout.layout){
        layout = layout.layout
    }
    layout[parseInt(blockId)].blockData.link = data.link;
    saveLayout(auth.me.id  , {layout: layout  , username: auth.me.username} , null);
  }
  function handleImageLinkChange(e) {
    setImageLinkInput(e.target.value);
    setBackground(e.target.value);
  }
  const onLogOut = (event) => {
    event.preventDefault();
    console.log("b4 logout" , history)
    logOutUser(history);
    console.log("log out called" , history);
    localStorage.clear();
  };
  const cancelSubscriptionClick = (email) => {
    let response = fetch("https://localhost:80/cancel-subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    //now logout
    logOutUser(history);
    history.push('/')
    localStorage.clear();
    console.log('response is: ', response)
  }
  function backgroundMenuOpenChange(newBg){
    /*
      Called when the main grid background changes
      this function saves the background as a cookie and in the backend
    */
   console.log('bg in react change ',newBg , background)
    if(state.layout[0].background === newBg){
      return;
    }
    if(typeof newBg !== "string"){
      let rgbaString = "rgba(" + parseInt(background.r) + "," + parseInt(background.g)
                        + ',' + parseInt(background.b) + ',' + parseFloat(background.a) + ')';
      background = rgbaString
    }
    else{
      background = newBg//'rgba(109, 114, 120,1)'
    }
    //setBackground();
    
    let newGridProps = gridProps;
    newGridProps.background = newBg;
    setGridProps(newGridProps);
    setBackground(background);
    saveLayout(auth.me.id , {background:background , layout: gridProps.gridLayout ,  username: auth.me.username} , {} );
  }
  function handleGridChange(){
    setGridNumber(0);
  }


  //if statement if: prop.gridlayout is empty --> make a div with the background

  //gridProps.background = background;
  //console.log("\n\nGRID PROPS: " , gridProps);
  console.log("STATE: " , state)
  if(typeof state.layout === null ){
    gridProps.gridLayout = [];
  }
  else if(state.layout[0].layout){
    gridProps.gridLayout = state.layout[0].layout;
  }
  else{
    gridProps.gridLayout = state.layout[0]
  }

  gridProps.background = background;
  console.log("PROP BG IS " , gridProps.background , gridProps.background[0])
  function getEmptyView(bg: string | {r:number , g:number , b:number , a:any}){
    console.log("IN EMPTY")

    if(bg === undefined){
      return "rgba(109, 114, 120,1)"
    }

    if(typeof bg !== 'string'){
      bg = 'rgba(' + bg.r.toString + ',' +  bg.g.toString + ',' +  bg.b.toString + ',' +  bg.a.toString + ')';
    }
    if(bg.includes("https")){
      console.log('return' , bg);
      return <NoWidgetViewLink bg={bg}/>
    }
    else{
      return <NoWidgetViewColor bg={bg}/>
    }
  }
  function deleteUserClick(){
    //api/users/delete/id
    console.log("DELTEING USER!" , deleteUser)
    let id = state.auth.me.id;
    deleteUser(state.auth.token , state.auth.me.id , history);
  }
  //console.log("GRID PROPS: " , gridProps)
  //console.log('num widgets: ', gridProps.gridLayout.length , emptyView ,gridProps.gridLayout.length === 0 )
  //{ !showSideBar && <button onClick={() => setShowSideBar(true)}>Hamburger button</button>}
  // add email => addWidget("Email")
  console.log('\n\nmain state',state);
  

  if(state.auth.me){
    if(state.auth.me.activeSubscription === false){
      logOutUser(history);
      localStorage.clear();
      history.push('/stripe')
    }
  }
  else{
    logOutUser(history);
    localStorage.clear();
    history.push('/stripe')
  }
  function exitTutorial(){
    setShowTutorial(false);
    hideTutorial(auth.me.id , history)
  }
  return (
      <>
          {showTutorial && <Tutorial onClose={exitTutorial}/>}
          <header key="head44">
            { !showSideBar &&
            <BurgerButton onClick={() => setShowSideBar(true)}>
              <BurgerBar open={true} />
              <BurgerBar open={true} />
              <BurgerBar open={true} />
            </BurgerButton>}
          </header>
          { showSideBar && 
    
          <SideBarContainter>
            <Sidebar backgroundColor={"black"} rootStyles={{color:"white",height: "440px"}}>
              <Menu     menuItemStyles={menuItemStyles}>
                <button onClick={() => { setShowSideBar(false); backgroundMenuOpenChange(background); } }>
                  X
                </button>
                <SubMenu label="Add Widget">
                  <MenuItem onClick={() => {}}>Email(coming soon)</MenuItem>
                  <MenuItem onClick={() => {addWidget("Notes")}}>Notes📝</MenuItem>
                  <MenuItem onClick={() => {addWidget("Image")}}>Image🌅</MenuItem>
                  <MenuItem onClick={() => {addWidget("Youtube")}}>Youtube Video📹</MenuItem>
                  <MenuItem onClick={() => {addWidget("News")}}>News🗞️</MenuItem>
                  <MenuItem onClick={() => {addWidget("Calendar")}}>Calendar📅</MenuItem>
                  <MenuItem onClick={() => {addWidget("NumberHabit")}}>Number Habit Tracker 
                        <SubMemuSumDesc>Track habits with daily checkmarks</SubMemuSumDesc>
                  </MenuItem>
                  <MenuItem onClick={() => {addWidget("CheckHabit")}}>CheckMark Habit Tracker 
                    <SubMemuSumDesc>Log numbers to track progress</SubMemuSumDesc>
                  </MenuItem>
                  <MenuItem onClick={() => {}}>To Do List(Coming Soon)</MenuItem>
                </SubMenu>
                <SubMenu label="Change Grid Background" onOpenChange={() => backgroundMenuOpenChange(background)}>
                    <RgbaColorPicker color={background} onChange={setBackground} />
                    <div> Or Enter link to image: </div>
                    <input onChange={handleImageLinkChange}></input> 
                    <button onClick={() => {setShowSideBar(false);backgroundMenuOpenChange(background);}}>Set Background</button>

                </SubMenu>
                {auth.isAuthenticated ?
                  <MenuItem component={<Link to={`/${auth.me.username}`}/>}>Profile</MenuItem>
                  :  (<MenuItem component={<Link to="/login"/>}>Log in</MenuItem>)
                }
                {auth.isAuthenticated ?
                  <></>
                  :  (<MenuItem>Register</MenuItem>)
                }
                {auth.isAuthenticated ?
                  <MenuItem onClick={onLogOut}>Log out</MenuItem>
                  :  <></>
                }
              <MenuItem onClick={() => cancelSubscriptionClick(state.auth.me.email)}>Cancel Subciption</MenuItem>
              </Menu>
            </Sidebar>
          </SideBarContainter>}
          {gridProps.gridLayout.length === 0 ?  getEmptyView(background) : <DropDrag {...gridProps} ref={childRef}></DropDrag> }
      </>
      );
}
//MenuItem onClick={() => deleteUserClick()}>Delete</MenuItem>
const mapStateToProps = (state) => ({
  auth: state.auth,
  state: state
});
export default compose(connect(mapStateToProps , {logOutUser , saveLayout ,hideTutorial, deleteUser},null, {forwardRef: true}))(Main);