import styled from "styled-components";
let EventDetail = styled.div<{width:number , height:number,backgroundColor:string}>`
    position: absolute;
    pointer-events:auto;
    background-color: ${props => props.backgroundColor};
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    `
const EventButton = styled.button<{backgroundColor:string , textColor:string}>`
    background-color: ${(props) => props.backgroundColor};
    border: thick double ${(props) => props.backgroundColor};
    width:90%;
    text-align: center;
    border-radius:7px;
    height:25px;
    color: ${(props) => props.textColor};
    white-space: nowrap;
`
const Label = styled.div`
  color:black;
`
const EventContainter = styled.div`
  display: block;
  color: white;
  position: relative;
  left: 0px;
  text-align: center;
  align-items: center;
  margin: 5px auto;
  width:90%;
  text-align: center;
`
const CalendarContainer = styled.div<{$buttonHeight:number , backgroundColor:string ,textColor:string , onHoverBackground:string}>`
  /* ~~~ container styles ~~~ */
  /* ... */
  color:${props => props.textColor};
 
  /* ~~~ navigation styles ~~~ */
  .react-calendar__navigation {
    display: flex;
    text-align:center;
    color:${props => props.textColor};
    .react-calendar__navigation__label {
      font-weight: bold;
      color:${props => props.textColor};
      background-color: ${props => props.backgroundColor};
    }

    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
      background-color: ${props => props.backgroundColor};
    }
  }

  /* ~~~ label styles ~~~ */
  .react-calendar__month-view__weekdays {
    text-align: center;
    color:${props => props.textColor};
  }

  /* ~~~ button styles ~~~ */
  .react-calendar__tile {
    margin: 3px;
    background-color: ${props => props.backgroundColor};
    color:${props => props.textColor};
    border: 0;
    border-radius: 3px;
    
    padding: 5px 0;


    &:hover {
      background-color: ${props => props.onHoverBackground};
    }

    &:active {
      background-color: ${props => props.backgroundColor};
    }
  }

  /* ~~~ day grid styles ~~~ */

  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%; 
    height: ${props => props.$buttonHeight}px;
    vertical-align: top;
    color: ${props => props.textColor};;
    .react-calendar__tile {
      max-width: initial !important;
    }
  }
  .react-calendar__month-view__days__day{
    color:${props => props.textColor};
    display: flex; justify-content: flex-start;
    flex-direction:column;
    &:has(> abbr) {
      abbr {
        color:${props => props.textColor};;
        text-align: right;
        width:100%;
      }
      div {
        color:${props => props.textColor};;
        position:relative;
        left:0px;
        text-align: right;
      }
    }
    
  }


  /* ~~~ neighboring month & weekend styles ~~~ */
  .react-calendar__month-view__days__day--neighboringMonth {
    height: ${props => props.$buttonHeight}px;
    color:${props => props.textColor};
  }
  .react-calendar__month-view__days__day--weekend {
    height: ${props => props.$buttonHeight}px;
    color:${props => props.textColor};
  }

  /* ~~~ active day styles ~~~ */
  .react-calendar__tile--range {
      box-shadow: 0 0 6px 2px black;
  }

  /* ~~~ other view styles ~~~ */
  .react-calendar__year-view__months, 
  .react-calendar__decade-view__years, 
  .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;

    &.react-calendar__year-view__months {
      grid-template-columns: 33.3% 33.3% 33.3%;
    }

    .react-calendar__tile {
      max-width: initial !important;
    }
  }
`;
let AddEventPopUp = styled.div<{width:number , height:number,backgroundColor:string}>`
    position: absolute;
    pointer-events:auto;
    background-color: ${props => props.backgroundColor};
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    `
let TextInput = styled.textarea<{ $height:number  , $backGroundColor:string, $textColor:string , $width:number}>`
    height:${props => props.$height}px;
    border-bottom: 1px solid ${props => props.$textColor || 'black' };
    width:${props => props.$width}px;
    background-color:${props => props.$backGroundColor};


    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;

    padding: 0;
    transition: background 0s ease-out 0s;
    color: ${props => props.$textColor};
    display: initial;
    outline: none;
    font-size: 15px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    &:focus {
        transition-duration: 0.3s;
      }
`
const EventTitleInput = styled.input`
  background-image: linear-gradient(#20aee3, #20aee3), linear-gradient(#bfbfbf, #bfbfbf);
  border: 0 none;
  border-radius: 0;
  box-shadow: none;
  float: none;
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
  color: #bfbfbf;
  min-height: 35px;
  display: initial;
  width: 100%;
  outline: none;
  font-size: 15px;
  &:focus {
      background-size: 100% 2px, 100% 1px;
      outline: 0 none;
      transition-duration: 0.3s;
      color: #525252;
    }
`;
export {CalendarContainer , EventContainter , EventButton , EventDetail,AddEventPopUp,TextInput,EventTitleInput,Label};
