
import React from "react";
import styled from "styled-components";
let OptionDiv = styled.div`width:20px;height:20px;z-index:500; `
let Img = styled.img`width:20px;height:20px;`
export const OptionBox = () => {

    return <OptionDiv id="OptionBox" className="Option">
                <Img src="https://cdn-icons-png.freepik.com/512/10278/10278809.png" alt="" className="img"/>
            </OptionDiv>

}