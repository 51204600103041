import {MenuItemStyles , menuClasses} from "react-pro-sidebar"
import styled from 'styled-components';

const  SideBarContainter= styled.div`
  float: left; 
  width: 100%; 
  height: 100%;
  background-color:transparent;
`
const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 35px;
  height: 35px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0px;
  &:focus {
    outline: none;
  }
`;
//${({ open }) => (open ? '#ccc' : '#333')};
  
const BurgerBar = styled.div<{ open: boolean }>`
  width: 35px;
  height: 4px;
  background: linear-gradient(to bottom, grey, black);
  border-radius: 2px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;

  :first-child {
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
  }

  :nth-child(2) {
    opacity: ${({ open }) => (open ? '0' : '1')};
    transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
  }

  :nth-child(3) {
    transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`;
//This is the main ivew if the user has no widgets and has a color background
const NoWidgetViewColor = styled.div<{ bg: string; }>`
  background-color: ${props => props.bg};
  background-size: 100% 100%;
  background-position: 'center',
  width:100%;
  height:100%;
  background-repeat: 'no-repeat',
` 
//This is the main ivew if the user has no widgets and has a background link
const NoWidgetViewLink = styled.div<{ bg: string; }>`
  background-image: ${props => 'url(' + props.bg + ')'};
  background-size: 100% 100%;
  background-position: 'center',
  width:100%;
  height:100%;
  background-repeat: 'no-repeat',
` 
const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
let dark =  {
    sidebar: {
      backgroundColor: 'red',
      color: 'white',
    },
    menu: {
      menuContent: 'black',
      icon: 'black',
      color:"white",
      hover: {
        backgroundColor: '#1b1f24',
        color: 'white',
      },
      disabled: {
        color: 'pink',
      },
    },
}


const SubMemuSumDesc = styled.div`
  font-size:11px;
`

const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '14px',
      fontWeight: 500,
      backgroundColor:'black'
    },
    icon: {
      color: dark.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: dark.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: "black"
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: "black",
      },
      '&:hover': {
        backgroundColor: hexToRgba(dark.menu.hover.backgroundColor,  1),
        color: dark.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };


export {menuItemStyles , SideBarContainter , NoWidgetViewColor , NoWidgetViewLink, BurgerButton, BurgerBar , SubMemuSumDesc}