
import axios from 'axios'


let base_url = process.env.REACT_APP_BASE_URL;
if(process.env.NODE_ENV !== "production"){
  base_url = "https://localhost:80"
}
export const attachTokenToHeaders = (getState) => {
    const token = getState().auth.token;
  
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };
  
    if (token) {
      config.headers['x-auth-token'] = token;
    }
  
    return config;
  };
export const addHabitEntry = (id, item, history) => async (dispatch, getState) => {
    console.log("**********IN add habit entry item" , id, item,history, '*********');
    try{
        console.log('loading... add habit action')
        const options = attachTokenToHeaders(getState);
        const response = await axios.put(base_url + `/habit/addEntry/${id}`, item, options);
        console.log("RESPONSE save habit item " , response);
        dispatch({
          type: "HabitEntryAdd",
          payload: item,
        });
    }
    catch{
        console.log("error in calendar save action")
    }
  };
export const editHabitName = (id, item, history) => async (dispatch, getState) => {
    console.log("**********IN add habit entry item" , id, item,history, '*********');
    try{
        console.log('loading... edit habit name action')
        const options = attachTokenToHeaders(getState);
        const response = await axios.put(base_url + `/habit/editName/${id}`, item, options);
        console.log("RESPONSE save habit item " , response);
        dispatch({
          type: "editHabitName",
          payload: item,
        });
    }
    catch{
        console.log("error in calendar save action")
    }
  };
export const editHabitEntry = (id, item, history) => async (dispatch, getState) => {
    try{
        console.log('loading edit habvit action...')
        const options = attachTokenToHeaders(getState);
        const response = await axios.put( base_url + `/calendar/addItem/${id}`, item, options);
        dispatch({
          type: "editHabitEntry",
          payload: item,
        });
    }
    catch{
        console.log("error in calendar save action")
    }
  };
  export const getBlockData = (blockId) => async (dispatch, getState) => {
    try {
      const options = attachTokenToHeaders(getState);
      console.log(options)
      const response = await axios.get(base_url + `/api/blockData/${blockId}`, options);
  
      dispatch({
        type: 'GotActions',
        payload: { messages: response.data.messages },
      });
    } catch (err) {
      dispatch({
        type: 'fail',
        payload: { error: err?.response?.data.message || err.message },
      });
    }
  };