import React from "react";
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { saveYoutube } from "../../../../store/actions/youtubeActions";
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
//let history = useHistory();
function extractYouTubeVideoId(url: string): string | null {
    // Split the URL by '?' to get the query string
    const queryString = url.split('?')[1];
    
    if (queryString) {
        // Split the query string by '&' to get the parameters
        const parameters = queryString.split('&');
        
        // Loop through the parameters to find the 'v' parameter
        for (const param of parameters) {
            const [key, value] = param.split('=');
            if (key === 'v') {
                return value;
            }
        }
    }
    
    return null; // Return null if 'v' parameter is not found
}



const YoutubeCSS = styled.div`
        position:relative;`
let ToolBoxContainer = styled.div`
    position: absolute;
    pointer-events:auto;
    `
const YoutubeToolBox = (props: {
   saveYoutube:any , state: any , blockId:number,onDelete(arg0: number): unknown , onChangeLink(arg0: string): unknown; 
}) => {

    let options =  ['Select','Delete', 'Change Link'];
    const [showOptions , setShowOptions] = useState(false);
    const [linkInput, setLinkInput] = React.useState('');
    const [showLinkInput, setShowLinkInput] = React.useState(false);
    function handleLinkInputChange(e) {
        setLinkInput(e.target.value);
      }
    let onEvent =  (e: { target: { value: any; }; }) => {
        let eventType = e.target.value;
        if(eventType === 'Delete'){
            props.onDelete(props.blockId);
        }
        else if(eventType === "Change Link"){
            console.log('here');
            setShowLinkInput(true);
        }
    }
    function onOptionClick(){
        if(showOptions){
            setShowOptions(false);
        }
        else{
            setShowOptions(true);
        }
    }
    return <>
            <div onClick={onOptionClick}><OptionBox/></div>
            { showOptions && <select id='vidToolBox' onChange={onEvent}>
                {options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
                ))}
            </select>}
            {
                showLinkInput ? <input id='vidLinkInput' onChange={handleLinkInputChange} defaultValue={""} type="text" placeholder="some text"     onKeyPress={async event => {
                    if (event.key === 'Enter') {
                      props.onChangeLink(linkInput);
                      console.log("VIDEO CHANGED!" , linkInput)
                      setShowLinkInput(false);
                      //updateBlock(props.state.layouts , props.state.auth ,props.blockId, {link:linkInput})
                    }
                  }}/> : ''
            }
            <MoveBox/>
        </>
}

interface Props {
    blockData:any;
    blockId:number;
    blockWidth:number;
    blockHeight:number;
    onDelete:any;
    state:any;
    saveYoutube:any;
  }
export const YoutubeBlock = (props:Props) => {
    let [hovering, setHovering] = useState(false);
    console.log("SAVE LAYOUT state" , props.state);
    //console.log("YOUTUBE PROPS",props.auth );
    function MouseOver(event) {
        setHovering(true);
    }
    function MouseOut(event){
        //FUnction is being called when toolbox opens up even though it's a child of the 
        // containing div which has a mouse out event
        if(event.target.id !== "vidToolBox" && event.target.id !== "vidLinkInput"){
            console.log("out of vid cause: !",typeof event.target , event.target, event.target.id);
            setHovering(false);
        }
    }

    const [videoLink , setVideoLink] = React.useState(props.blockData.link);
    async function updateBlock(layout , auth, blockId , data){
        //update redux state
        // to do make a actual action and iter thru all keys in data
        console.log("IN UPDATE BLOCK LAYOUT: " , layout)
        layout[0].layout[parseInt(blockId)].blockData.link = data.link;
        console.log("\n\non yt video save: " , layout)
        props.saveYoutube(props.state.auth.me.id  , data.link ,blockId, null);
    }
    async function handleLinkChange(link:string){
        setVideoLink(link);
        await updateBlock(props.state.layout ,props.state.auth , props.blockId , {link:link} )
    }
    function stopPropagation(event: {target: any;stopPropagation: () => void; }){
        if(event.target.id !== "MoveBox"){
          event.stopPropagation();
        }
      };
    return <div onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut}>
                <ToolBoxContainer>
                    {hovering && <YoutubeToolBox saveYoutube={saveYoutube} state={props.state} blockId={props.blockId} onDelete={props.onDelete} onChangeLink={(link: string) => { handleLinkChange(link); return; } } ></YoutubeToolBox>}
                </ToolBoxContainer>
                <iframe
                    width={props.blockWidth} 
                    height={props.blockHeight}
                    src={`https://www.youtube.com/embed/${extractYouTubeVideoId(videoLink)}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; "
                    title="Embedded youtube"
                />
        </div>
}
const mapStateToProps = (state) => ({
    state: state,
  });

export default compose(connect(    mapStateToProps , {saveYoutube} ,null ))(YoutubeBlock);


/*

Make store  hold layout data
Make reducer to maniplate store
MAke Action to tell reducer to manipulate the layout data in the store

*/
  
