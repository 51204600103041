function formatDate(date) {


    
    let options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'America/Los_Angeles' };
    let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate.replace(/\//g, '-');
}
// Function to generate the list of dates
export function generateLast50WeeksDates() {
    let dates = [];
    let currentDate = new Date();

    // Move to the previous Monday
    currentDate.setDate(currentDate.getDate() - (currentDate.getDay()) % 7);

    for (let i = 0; i < 50; i++) {
        for (let j = 0; j < 7; j++) {
            dates.push(formatDate(currentDate));
            currentDate.setDate(currentDate.getDate() - 1);
        }
    }

    return dates.reverse();
}

// Helper function to format the date as mm-dd-yyyy


// Function to generate dates for the current week excluding Sunday if today is not Sunday, else include all days
export function generateCurrentWeekDates() {
    let dates = [];
    let currentDate = new Date();
    // 0 1 2 3 4 5 6
    //n  y y y y 
    // Get the current day of the week (0 is Sunday, 6 is Saturday)
    let currentDayOfWeek = currentDate.getDay();
    
    // Adjust to the start of the week (Monday)
    let startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDayOfWeek + 1);

    // Check if startOfWeek is in the next month or year
    if (startOfWeek > currentDate) {
        startOfWeek.setDate(startOfWeek.getDate() - 7);
    }
    
    // Add dates from Monday to Saturday
    for (let i = 0; i < currentDayOfWeek; i++) {
        let day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        // Exclude Sunday if today is not Sunday
        if (!(currentDayOfWeek === 0 && i === 0)) {
            dates.push(formatDate(day));
        }
    }
    
    return dates;
}
export function getLast12Months() {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const currentMonthIndex = new Date().getMonth(); // getMonth() returns 0-11
    let last12Months = [];

    for (let i = 1; i <= 12; i++) {
        let monthIndex = (currentMonthIndex + i) % 12;
        last12Months.push(months[monthIndex]);
    }

    return last12Months;
}




