//To do: 
// 1. get the the following data from the db?
let layouts:any = localStorage.getItem('layouts'); 
let background:any = localStorage.getItem('background'); 



let initialState = [ {layout: [] , background:'rgba(60,60,60,.3)'}]  ;
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, { type, payload }) {
    console.log('\n\n\n\n\n\nin layout reducerL ', type ,payload )
    let layout;let link;
    switch (type) {
      case 'LayoutChange':
        console.log("PAYLOAD IS "  , JSON.stringify([payload]))
        localStorage.setItem('layouts',JSON.stringify([payload]));
        localStorage.setItem('background',JSON.stringify(payload.background));

        //to do somehow index to change the ith layout
        return [payload];
      case 'imageLinkUpdate':
        layout = JSON.parse(localStorage.getItem('layouts'));
        console.log('in img update', payload)
        layout[0].layout[payload.blockId].blockData.link = payload.link;
        console.log('after update image link: ' , layout)
        localStorage.setItem('layouts' , JSON.stringify( layout));
        return layout
      case 'youtubeLinkUpdate':
        let link = payload.link;
        let blockId = payload.blockId;
        layout = JSON.parse(localStorage.getItem('layouts'));
        layout[0].layout[blockId].blockData.link = link;
        localStorage.setItem('layouts' , JSON.stringify( layout));
        return layout
      case 'styleUpdate':
        console.log('in correct switch styleUpdate' , payload.styleDict , payload.blockId);
        let style = payload.styleDict;
        let blkId = payload.blockId;
        layout = JSON.parse(localStorage.getItem('layouts'));
        layout[0].layout[blkId].blockData.widgetStyle = style;
        localStorage.setItem('layouts' , JSON.stringify( layout));
        return layout
      // eslint-disable-next-line no-fallthrough
      case 'HabitEntryAdd'://maybe only add data to db instead of storing as a cookie here?
        console.log('in habit entry add reducer' , payload.date , payload.value)
        layout = JSON.parse(localStorage.getItem('layouts'));
        if(layout[0].layout[payload.blockId].blockData.habitEntrys === undefined){
          layout[0].layout[payload.blockId].blockData.habitEntrys = {}
        }
        layout[0].layout[payload.blockId].blockData.habitEntrys[payload.date] = payload.value;
        localStorage.setItem('layouts' , JSON.stringify( layout));
        return layout
      case 'editHabitName'://maybe only add data to db instead of storing as a cookie here?
        layout = JSON.parse(localStorage.getItem('layouts'));
        layout[0].layout[payload.blockId].blockData.habitName = payload.name;
        localStorage.setItem('layouts' , JSON.stringify( layout));
        return layout
      // eslint-disable-next-line no-fallthrough
      case 'noteUpdate':
        console.log('in note reducer');
        layout = JSON.parse(localStorage.getItem('layouts'));
        console.log("GLOBAL NEW TREE IS " , payload.tree)
        layout[0].layout[payload.blockId].blockData.fileTree = payload.tree;
        localStorage.setItem('layouts' , JSON.stringify( layout));
        return layout
      default:
        layout = localStorage.getItem('layouts');
        if(layout === '[]' || layout === '[{}]' || layout === null || layout === '[null]'){
          localStorage.setItem('layouts' , JSON.stringify(initialState));

          // try to check if a layout exists

          return initialState
        }
        console.log('cookie' , localStorage.getItem('layouts'))
        return JSON.parse(localStorage.getItem('layouts'))
        
    }
  }
  