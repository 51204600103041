
import styled from 'styled-components';

const SourceContainer = styled.div`display:flex;flex-direction: row;`;
const HeadlineContainer = styled.div<{ $textColor?:string}>`
    display:flex;
    flex-direction: row;
    justify-content:space-between
    color:
    color:${props => props.$textColor || "#000000"};
    `;
const HeadlineLink = styled.a<{ $textColor?:string}>`
    color: ${props => props.$textColor || "#000000"};;
    text-decoration: none;
    padding: 10px;
`;
const ToolBoxContainer = styled.div`
    position: absolute;
    pointer-events:auto;
    `
const NewsContainer = styled.div<{ $textColor?:string,$backgroundColor?: string;$height?: number;$width?: number; }>`
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 3px;
  height: ${props => props.$height || 400}px;
  width: ${props => props.$width || 400}px;
  overflow: auto;
  background-color: ${props => props.$backgroundColor || "#BF4F74"};
  color:${props => props.$textColor || "#000000"};
`;
export {SourceContainer , HeadlineContainer , HeadlineLink , NewsContainer, ToolBoxContainer}