export function getDefaultParams(state , blockId){
    let params = {bg:'rgba(255,255,255,.5)' , textColor:'black' }
    let layout = state.layout[0].layout;
    try{
      if(layout[blockId].blockData.widgetStyle.bg && layout[blockId].blockData.widgetStyle.textColor
       ){
       params.bg = layout[blockId].blockData.widgetStyle.bg;
       params.textColor = layout[blockId].blockData.widgetStyle.textColor;
       return params
      }
    }
    catch{
      return params;
    }
    return params
}