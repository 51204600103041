import React from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';

import { useFormik } from 'formik';

import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loginUserWithEmail } from '../../store/actions/authActions';
import {loadLayouts} from '../../store/actions/userActions';
import { FACEBOOK_AUTH_LINK, GOOGLE_AUTH_LINK, OUTLOOK_AUTH_LINK } from '../../constants';
import { loginSchema } from './validation';
import './styles.css';
import backgroundImage from '../Stripe/ai_landscape.jpg';

import styled from 'styled-components';
let StyledInput = styled.input`
  background-color: white;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid lightblue;
  height:16px;
  width:200px;
  `
const StyledButton = styled.button`
  background-color: rgb(75 131 70 / 56%);  /* Button color */
  color: white;                /* Text color */
  border: none;                /* Remove border */
  border-radius: 5px;         /* Rounded corners */
  padding: 10px 20px;         /* Padding */
  font-size: 16px;            /* Font size */
  cursor: pointer;             /* Pointer cursor on hover */
  transition: background-color 0.3s ease;  /* Transition for hover effect */
  margin-bottom: 10px;
  &:hover {
    background-color: rgb(75 131 70);  
  }
  
  &:disabled {
    background-color: rgb(75 131 70 / 56%);  /* Gray when disabled */
    cursor: not-allowed;         /* Change cursor when disabled */
  }
`;

const Login = ({ auth, history, loginUserWithEmail,loadLayouts }) => {
  console.log("GOOGLE LINK " , GOOGLE_AUTH_LINK);
  let env = process.env.NODE_ENV ;
  const googleAuthLink = process.env.NODE_ENV === 'production' ? "https://prod3-345a5a1ba04b.herokuapp.com/auth/google": "https://localhost:80/auth/google";
  //console.log("LIBNK" , googleAuthLink)
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      console.log("ON SUMBIT" , values ,history)
      loginUserWithEmail(values, history);
      console.log('b4 loading layouts' , auth.me.username)
      
    },
  });

  console.log("AUTH IS: " , auth)
  if (auth.isAuthenticated && typeof auth.me !== 'undefined') {
    console.log('front end data! ' , auth);
    console.log("AUTHED: ", auth.me.username)
    //loadLayouts(auth.me.username,history);
    return <Redirect to="/grid" />;
  }
  /*
          <h2>Log in with social media</h2>
          <a className="google btn" href={googleAuthLink}>
            <span className="login-text">
              <i className="fa fa-google fa-fw" /> Login / Sign-up with Google
            </span>
          </a>
  */

  return (
    <div className="login" style={{display: 'flex', flexDirection: 'column' ,alignItems: 'center', justifyContent: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',  // Ensures the image covers the entire div
      backgroundPosition: 'center',  // Centers the image in the div
      height: '100vh',  // Adjust height as needed
      width: '100%',  // Adjust width as needed
    }} >
        <div  style={{justifyContent: 'center',backgroundColor:'rgba(0,0,0,0.69)' , width:'50%', borderRadius:'16px'}}>
        <form  style={{width:'100%',display: 'flex', flexDirection: 'column' ,alignItems: 'center', justifyContent: 'center'}} onSubmit={formik.handleSubmit}>
          <h2 style={{color:'white'}}>Login</h2>
          
          <StyledInput
            placeholder="Email address"
            name="email"
            className="text"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="error">{formik.errors.email}</p>
          ) : null}
          <br/>
          <StyledInput
            placeholder="Password"
            name="password"
            type="password"
            className="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          <br/>
          {formik.touched.password && formik.errors.password ? (
            <p className="error">{formik.errors.password}</p>
          ) : null}
          
          {auth.error && <p className="error">{auth.error}</p>}
          <div>
            <StyledButton
              className="btn submit"
              disabled={auth.isLoading || !formik.isValid}
              type="submit"
            >
              Log in now
            </StyledButton>
          </div>
          <div style={{color:'white'}}>
            Don't have an account?{' '}
            <Link className="bold" to="/stripe" style={{color:'orange'}}>
              Register
            </Link>
          </div>
        </form>
        </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default compose(withRouter, connect(mapStateToProps, { loginUserWithEmail,loadLayouts }))(Login);
