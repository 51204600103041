import {
  LOGIN_WITH_GOOGLE_OAUTH_LOADING,
  LOGIN_WITH_GOOGLE_OAUTH_SUCCESS,
  LOGIN_WITH_GOOGLE_OAUTH_FAIL,
  LOGOUT_SUCCESS,
  LOGIN_WITH_EMAIL_LOADING,
  LOGIN_WITH_EMAIL_SUCCESS,
  LOGIN_WITH_EMAIL_FAIL,
  ME_LOADING,
  ME_SUCCESS,
  ME_FAIL,
} from '../types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  me: null,
  error: null,
  appLoaded: false,
  googleRefreshToken: localStorage.getItem('googleRefreshToken'),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  console.log('payload' , payload , type)
  switch (type) {
    case ME_LOADING:
      return {
        ...state,
        isLoading: true,
        appLoaded: false,
        error: null,
      };
    case LOGIN_WITH_EMAIL_LOADING:
    case LOGIN_WITH_GOOGLE_OAUTH_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOGIN_WITH_EMAIL_SUCCESS:
      console.log("succsuful login: " , payload);
      localStorage.setItem('token', payload.token);
      localStorage.setItem('googleRefreshToken', JSON.stringify(payload.me.googleRefreshToken));
      try{
        localStorage.setItem('layouts' ,  JSON.stringify( [payload.me.layouts[0]] ));
        localStorage.setItem('background' ,   payload.me.layouts[0].background );
      }
      catch{
        localStorage.setItem('layouts' ,  JSON.stringify( [{layout:[] , background:"rgba(200,200,200,.5)"}]));
        localStorage.setItem('background' ,   "rgba(200,200,200,.5)" );
      }
     
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: payload.token,
        me: payload.me,
        error: null,
      };
    case "SLACK_LOGIN_SUCCESS":
      localStorage.setItem('slackToken', payload.slackToken);
    case LOGIN_WITH_GOOGLE_OAUTH_SUCCESS:
      console.log("AUTH REDUCER PAYLOAD: " , payload)
      localStorage.setItem('token', payload.token);
      localStorage.setItem('googleRefreshToken', JSON.stringify(payload.me.googleRefreshToken));
      localStorage.setItem('layouts' ,  JSON.stringify( [payload.me.layouts[0].layout] ));
      localStorage.setItem('background' ,   payload.me.layouts[0].background );
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: payload.token,
        me: payload.me,
        error: null,
        googleRefreshToken:payload.me.googleRefreshToken
      };
    case ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        me: payload.me,
        error: null,
        appLoaded: true,
      };
    case ME_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        me: null,
        error: null,
        appLoaded: true,
      };
    case LOGOUT_SUCCESS:
    case LOGIN_WITH_EMAIL_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        me: null,
        isAuthenticated: false,
        isLoading: false,
        error: null, //payload message ovde i razdvoj logout i fail
      };
    default:
      return state;
  }
}
