import { combineReducers } from 'redux';

import authReducer from './authReducer';
import registerReducer from './registerReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import messageReducer from './messageReducer';
import layoutReducer from './layoutReducer';
import calendarReducer from './calendarReducer';
import slackReducer from './slackReducer';
import tutorialReducer from './tutorialReducer';

//import subscriptionStatus from './'
export default combineReducers({
  auth: authReducer,
  register: registerReducer,
  message: messageReducer,
  user: userReducer,
  users: usersReducer,
  layout: layoutReducer,
  calendar: calendarReducer,
  slack: slackReducer,
  showTutorial: tutorialReducer,
});
