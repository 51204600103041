import axios from 'axios';

import {
  REGISTER_WITH_EMAIL_LOADING,
  REGISTER_WITH_EMAIL_SUCCESS,
  REGISTER_WITH_EMAIL_FAIL,
} from '../types';

export const registerUserWithEmail = (formData, history) => async (dispatch, getState) => {
  let base_url = process.env.REACT_APP_BASE_URL;
  if(process.env.NODE_ENV !== "production"){
    base_url = "https://localhost:80"
  }
  dispatch({ type: REGISTER_WITH_EMAIL_LOADING });
  try {
    console.log("SENING: " , formData)
    await axios.post(base_url + "/auth/register", formData);
    dispatch({
      type: REGISTER_WITH_EMAIL_SUCCESS,
    });
    history.push('/login');
  } catch (err) {
    dispatch({
      type: REGISTER_WITH_EMAIL_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
