/* eslint-disable react/style-prop-object */

import React, { useState } from "react";
import styled from "styled-components";
import moment, { Moment } from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { editCalendarItem } from '../../../../store/actions/calendarActions';
import { useDispatch } from 'react-redux';
//import { addCalendarItem } from '../../store/actions/calendarActions';
// @ts-ignore
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { AddEventPopUp,TextInput,EventTitleInput,Label } from "./CalendarStyles";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
//import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';



import  { CalendarContainer,EventDetail , EventContainter,EventButton}  from './CalendarStyles';

const StyledButton = styled.button<{active:boolean , backgroundColor:string}>`    
    background-color: ${({ active , backgroundColor}) => (active ? backgroundColor :  'grey')};
`
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
export const EventEdit = (props: { backgroundColor:string , eventData:any  ,width:number , height:number  ,setActiveEditEvent(arg0:boolean):any  , state ,editCalendarItem}) => {
    console.log("EVENT DATA: " , props.eventData)
    const dispatch = useDispatch();
    let [title , setTitle] = useState(props.eventData.title);
    let [isEvent , setIsEvent] = useState(props.eventData.isEvent);
    let [timeRange, onChange] = useState<Value>(props.eventData.timeRange);
    let [description , setDescription] = useState(props.eventData.description);
    function handleTitleChange(e){
        setTitle(e.target.value);
    }
    function handleDescChange(e){
        setDescription(e.target.value);
    }
    function handleEventClick(){
        if(!isEvent){
            setIsEvent(true)
        }
    }
    function handleTaskClick(){
        if(isEvent){
            setIsEvent(false);
        }
    }
    function editCalendarItem(){
        //console.log("\n\n\n***EVENT SAVW DATA****\n" , props.addCalendarItem, timeRange , title,description ,isEvent)
        props.setActiveEditEvent(false);
        dispatch(props.editCalendarItem(props.state.auth.me.id , {'timeRange': timeRange, 'title': title, 'description':description , 'isEvent':isEvent , 'eventId':props.eventData.eventId} , {} ));       
    }
    
    return <EventDetail width={props.width}  height={props.height} backgroundColor="white">
                <EventTitleInput defaultValue={props.eventData.title} onChange={handleTitleChange} placeholder="Event or Task Name"></EventTitleInput>
                <StyledButton backgroundColor={props.backgroundColor} active={isEvent} onClick={handleEventClick} > Event</StyledButton>
                <StyledButton backgroundColor={props.backgroundColor} active={!isEvent} onClick={ handleTaskClick} > Task </StyledButton>
                {isEvent ? <DateTimeRangePicker   onChange={onChange} value={timeRange} disableCalendar={true} disableClock={true} />: '' }
                <Label>Task Description</Label>
                <TextInput defaultValue={props.eventData.description} onChange={handleDescChange} placeholder={'Add Description'} $width={props.width * .9} $height={props.height * .33} $backGroundColor="light grey" $textColor="black" ></TextInput>
                <StyledButton active={true} backgroundColor={props.backgroundColor} onClick={() => props.setActiveEditEvent(false)}>Cancel</StyledButton>
                <StyledButton active={true} backgroundColor={props.backgroundColor} onClick={() => editCalendarItem()}>Save</StyledButton>
            </EventDetail>
}
const mapStateToProps = (state) => ({
    state:state
  });
export default compose(connect(    mapStateToProps , {editCalendarItem} ,null ))(EventEdit);
//{"2024-04-18":[,{"timeRange":["2024-04-16T09:00:00.000Z","2024-04-16T10:00:00.000Z"],"title":"2am","description":"","isEvent":true}]}
