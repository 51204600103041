import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  EDIT_USER_LOADING,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from '../types';

import { logOutUser, loadMe } from './authActions';


let base_url = process.env.REACT_APP_BASE_URL;
if(process.env.NODE_ENV !== "production"){
  base_url = "https://localhost:80"
}
export const editUser = (id, formData, history) => async (dispatch, getState) => {
  console.log("IND REDEUX" , formData);
  dispatch({
    type: EDIT_USER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.put(base_url + `api/users/${id}`, formData, options);
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: { user: response.data.user },
    });
    // edited him self, reload me
    if (getState().auth.me?.id === response.data.user.id) dispatch(loadMe());
    history.push(`/${response.data.user.username}`);
  } catch (err) {
    dispatch({
      type: EDIT_USER_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const saveLayout = (id, layout, history) => async (dispatch, getState) => {
  console.log("NEW LAYOUT IS BEING SAVED" , layout)
  try{
    const options = attachTokenToHeaders(getState);
    console.log("ACTION SAVE LAYOUT: " , layout);
    const response = await axios.put(base_url + `/api/users/layoutChange/${id}`, layout, options);
    dispatch({
      type: "LayoutChange",
      payload: layout ,
    });
  }
  catch{
    console.log("Error in save layout action");
  }
};
export const loadLayouts = (username, history) => async (dispatch, getState) => {

  console.log("IN load layout",username );
  try{
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(base_url + `/api/users/${username}`, options);
    console.log('in load',response)
    dispatch({
      type: "LayoutChange",
      payload: { layout:response.data.layouts },
    });
  }

  catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
};
}
export const saveBlockChange = (id , blockId , history , data) => async (dispatch, getState) =>{
  /*
  Params: id= id of the user
          blockId=Id of the block to add/change keys in
          history:
          data: dict of keys that may or may not be in the layouts[blockId] 
  
  This Action sends makes sure the layouts[blockId] block is updated with all data in the data dict
  */
 console.log("PARAMS in save block change: " , id , blockId , {} , data);
 console.log("IN save block " , data);
 console.log(getState)
 const options = attachTokenToHeaders(getState);
 const response = await axios.put(base_url + `/api/users/blockChange/${id}/${blockId}`, data, options);
 console.log("RESPONSE SAVE layout " , response);
 dispatch({
   type: "LayoutChange",
   payload: { data },
 });
}


export const getProfile = (username, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROFILE_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    
    const response = await axios.get(base_url + `/api/users/${username}`, options);

    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: { profile: response.data.user },
    });
  } catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_PROFILE_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteUser = (id, history) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_USER_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(base_url + `/api/users/${id}`, options);

    //logout only if he deleted himself
    if (getState().auth.me.id === response.data.user.id) {
      dispatch(logOutUser(id, history));
    }
    history.push('/users');
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: { message: response.data.user },
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
