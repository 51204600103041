
import React, { useState } from "react";
import styled from "styled-components";
import moment, { Moment } from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { addCalendarItem } from '../../../../store/actions/calendarActions';
import { useDispatch } from 'react-redux';
//import { addCalendarItem } from '../../store/actions/calendarActions';
// @ts-ignore
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { AddEventPopUp,TextInput,EventTitleInput,Label } from "./CalendarStyles";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
//import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';





const StyledButton = styled.button<{active:boolean , backgroundColor:string}>`    
    background-color: ${({ active , backgroundColor}) => (active ? backgroundColor :  'grey')};
`
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
export const AddEvent = (props: { width:number , height:number , color:string , startDate:Date ,setActiveAddEvent(arg0:boolean):any  , state , addCalendarItem}) => {
    let [title , setTitle] = useState('');
    const dispatch = useDispatch();
    
    let [isEvent , setIsEvent] = useState(false);
    const [timeRange, onChange] = useState<Value>([props.startDate, props.startDate]);
    let [description , setDescription] = useState('');
    function handleTitleChange(e){
        setTitle(e.target.value);
    }
    function handleDescChange(e){
        setDescription(e.target.value);
    }
    function handleEventClick(){
        if(!isEvent){
            setIsEvent(true)
        }
    }
    function handleTaskClick(){
        if(isEvent){
            setIsEvent(false);
        }
    }
    function saveCalendarItem(){
        //console.log("\n\n\n***EVENT SAVW DATA****\n" , props.addCalendarItem, timeRange , title,description ,isEvent)
        props.setActiveAddEvent(false);
        dispatch(props.addCalendarItem(props.state.auth.me.id , {'timeRange': timeRange, 'title': title, 'description':description , 'isEvent':isEvent , 'eventId':Math.random()} , {} ));       
    }
    
    return <AddEventPopUp width={props.width}  height={props.height} backgroundColor="white">
            <EventTitleInput onChange={handleTitleChange} placeholder="Event or Task Name"></EventTitleInput>
            <StyledButton backgroundColor={props.color} active={isEvent} onClick={handleEventClick} > Event</StyledButton>
            <StyledButton backgroundColor={props.color} active={!isEvent} onClick={ handleTaskClick} > Task </StyledButton>
            {isEvent ? <DateTimeRangePicker   onChange={onChange} value={timeRange} disableCalendar={true} disableClock={true} />: <Label>Task</Label> }
            <TextInput onChange={handleDescChange} placeholder={'Add Description'} $width={props.width * .9} $height={props.height * .33} $backGroundColor="light grey" $textColor="black" ></TextInput>
            <StyledButton active={true} backgroundColor={props.color} onClick={() => props.setActiveAddEvent(false)}>Cancel</StyledButton>
            <StyledButton active={true} backgroundColor={props.color} onClick={() => saveCalendarItem()}>Save</StyledButton>
            </AddEventPopUp>
}
const mapStateToProps = (state) => ({
    state:state
  });
export default compose(connect(    mapStateToProps , {addCalendarItem} ,null ))(AddEvent);
