
import React from 'react';
import { RgbaColorPicker} from "react-colorful";
import { RgbColorPicker } from 'react-colorful';
import { MoveBox } from "../../ToolBox/MoveBox";
import { OptionBox } from "../../ToolBox/OptionBox";
import styled from 'styled-components';
let Focus = styled.span`position: absolute;`
let ColorContainer = styled.div`z-index:600`;
let ToolBoxContainer = styled.div`position: absolute;pointer-events:auto;z-index:1001;right:0px;top:0px;`
let Left = styled.div`float:left;`
let Right = styled.div`float:Right;`
export const NotesToolBox = (props: { activeNote:number ,  toFileList:any , blockId:number,onDelete(arg0: number): unknown, onChangeBackground(arg0:any ): unknown,
     onChangeTextColor(arg0:any ): unknown,onChangedProperty(arg0:boolean): unknown;
}) => {

    
    let options =  ['Select','Delete Widget','Change Background' , 'Change Text Color'];
    const [showOptions , setShowOptions] = React.useState(false);
    const [showBgColorPicker, setShowBgColorPicker] = React.useState(false);
    const [showTextColorPicker, setShowTextColorPicker] = React.useState(false);
    function handleTextColorChange(e) {
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeTextColor(color);
        props.onChangedProperty(true);
    }
    function handleBgColorChange(e) {
        console.log(e)
        let color = "rgba( " + e.r +',' + e.g +',' + e.b +','+ e.a+ ")";
        props.onChangeBackground(color);
        props.onChangedProperty(true);
    }
    let onEvent =  (e: { target: { value: any; }; }) => {
        let eventType = e.target.value;
        if(eventType === 'Delete Widget'){
            props.onDelete(props.blockId);
            console.log('delete click')
        }
        else if(eventType === 'Change Background'){
            setShowBgColorPicker(true)
        }
        else if(eventType === 'Change Text Color'){
            setShowTextColorPicker(true)
        }
    }

    function onOptionClick(){
        if(showOptions){
            setShowOptions(false);
            setShowBgColorPicker(false);
            setShowTextColorPicker(false)
        }
        else{
            setShowOptions(true);
        }
    }
    console.log("\n\n\n\nFILE LIST: " , props.toFileList , typeof props.toFileList)
    return (<ToolBoxContainer>
            <Left>
                <div onClick={onOptionClick}><OptionBox/></div>
                {showOptions && <select id='NoteToolBox' onChange={onEvent}>
                    <optgroup/>
                    {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                    ))}
                </select>
                }
            
            <Focus/>
            <ColorContainer>
            {
                showBgColorPicker ? <RgbaColorPicker onChange={handleBgColorChange} /> : ''
            }
            {
                showTextColorPicker ? <RgbaColorPicker onChange={handleTextColorChange} /> : ''
            }
            </ColorContainer>
            </Left>
            <Right>
            <MoveBox/>
            { props.activeNote !== -1 && <button onClick={() => { props.toFileList()}}>File list</button> } 
            </Right>
        </ToolBoxContainer>
        )
}