/*
To Do.
    0. OnClick not working if in devTools not open?
    1. add  on click for each emailPreview
        1.1 pull up text/emai content
        1.2 add back button
        1.3 add reply button
            1.3.1 go to bottomm of block
            1.3.2add text box with send and attachments button on bottom
    2. add star and delete buttons on hover
    3. fix hover bugs when changing block width 
    4. Add TooloBox to change, delete it, colors etc
    5. Add Searchbar

*/

import parse from 'html-react-parser';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { EmailContainer , ReplyButton, Date ,InboxList, Sender,EmailPreviewRow,BackButton,EmailMainView,Subject, SearchBar,MenuButton,Row  } from '../EmailStyles';
import { EmailData } from '../EmailDataTypes';
import { EmailToolBox } from '../EmailToolBox';
import {getEmailData , getDefaultParams , getMessageContent,getAttachmentIds , getMimeTypes} from '../EmailHelper'
import { Base64 } from "js-base64";
import { SendMessageBox } from '../MessageBox';
import Color from 'color';
import FilePreview from './FilePreview';

import { updateStyles } from '../../helpers';
let base_url = process.env.REACT_APP_BASE_URL;
if(process.env.NODE_ENV !== "production"){
    base_url = "https://localhost:80"
  }
export const EmailBlock = (height:number,width:number ,blockId:number, state , onDelete , saveLayout) => {
    console.log("STATE IS " , state)
    let params = getDefaultParams(state , blockId)

    // changedProperty is given to the Toolbox and set to true if the toolBox changes 
    // on a hover out of the widget it is checked if true --> if so then communicate changes to db
    // done this way because users mess around with the color changers a lot and it wont make sense
    // to tell the db about every change
    let [changedProperty , setChangedProperty] = useState(false); 

    function stopPropagation(event: {target: any;stopPropagation: () => void; }){
        if(event.target.id !== "MoveBox"){
            event.stopPropagation();
        }
    };

    let [updateThreads , setUpdateMessages] = useState(0);
    let [ threadAttachments , setThreadAttachments] = useState({});// messageId: [attachments] for each message on the 

    useEffect(() => {
        const interval = setInterval(() => {
          console.log('This will run every 25 seconds!');
          setUpdateMessages(updateThreads + 1);
        }, 1000 * 225000);
        return () => clearInterval(interval);
      }, []);



    const [threads, setThreads] = useState<any[]>([]);
    let [hovering, setHovering] = useState(false);
    const [activeThreads , setActiveThreads] = useState<boolean[]>([]);
    const [showSingleEmail , setShowSingleEmail] = useState<boolean>(false);
    const [singleEmailToShow , setSingleEmailToShow] = useState<number>(0);
    let [background , setBackground] = useState(params.bg);
    let [showMainMessageBox, setShowMainMessageBox] = useState(false);
    let [searchInput , setSearchInput] = useState('none');
    async function getThreads(query:string,label:string){ 
        //get new messages from gmail
        try { 
            const token = state.auth.token;
            const config = {
                headers: {
                'Content-type': 'application/json',
                },
            };
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const response = await axios.get('https://localhost:80/mail/readEmail/' + state.auth.me.email+ '/' +
            encodeURIComponent(state.auth.me.googleRefreshToken) + '/' + query + '/' +label , config);
            response.data.sort((a, b) => b.messages[0].internalDate - a.messages[0].internalDate);
            if(threads === null){
                setThreads(response.data);
            }
            else{
                setThreads(response.data);
            }
            setActiveThreads(new Array(threads.length).fill(false));
        } 
        catch (error) { 
            console.log('error getting messages:[')
            console.error(error);
        }
    }
    async function getThreadAttachments(thread){
        console.log("thread attachments called" , singleEmailToShow);
        if(typeof thread === 'undefined'){
            return;
        }
        let messageIds = thread.messages.map(message => message.id);
        try { 
            const token = state.auth.token;
            const config = {
                headers: {
                'Content-type': 'application/json',
                },
            };
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            let threadAttachments ={};
            for(let j = 0; j < messageIds.length; j++ ){
                let messageAttachments = []
                let attachmentIds = getAttachmentIds(thread.messages[j]);
                let mimeTypes = getMimeTypes(thread.messages[j]);
                for(let i = 0; i < attachmentIds.length;i++ ){
                    const response = await axios.get(base_url + '/mail/readAttachment/' + state.auth.me.email+ '/' +
                    encodeURIComponent(state.auth.me.googleRefreshToken) + '/' + messageIds[j]+'/'+ attachmentIds[i] , config);
                    messageAttachments.push( [ response.data.data , mimeTypes[i] ]) ;
                }
                threadAttachments[messageIds[j]] = messageAttachments;
            }
            return threadAttachments;
        } 
        catch (error) { 
            console.log('error getting messages:[')
            console.error(error);
            return {};
        }
    }
    useEffect( () => {getThreads(searchInput , 'none')}, [updateThreads]);

    let backgroundObj = Color(background)
    let secondaryColor = 'grey'
    let defaultTextColor = 'black'
    if( backgroundObj.isDark()){
        secondaryColor = 'rgb(43, 43, 43)';
        defaultTextColor = 'white'
    }
    let [textColor, setTextColor] = useState(params.textColor);
    
    function handleHoverOverEmailPreview(previewData: EmailData){

    }

    function onHoverEmailPreview(emailIndex:number){
       let shallow = [...activeThreads];
       shallow[emailIndex] = true;
       setActiveThreads(shallow);
       //console.log(activeThreads);
    }
    function onMouseOutEmailPreview(emailIndex:number){
        let shallow = [...activeThreads];
        shallow[emailIndex] = false;
        setActiveThreads(shallow);
        //console.log(activeThreads);
     }
    
    const onPreviewClicked = async (emailIndex:number) => {
        setShowSingleEmail(true);
        setSingleEmailToShow(emailIndex);
        setThreadAttachments(await getThreadAttachments(threads[emailIndex]));
        console.log("Preview Clicked!");
    }
    function getEmailRowPreview(previewData: EmailData , emailIndex:number){
        //console.log('right before display row, ', width)
        let emailPreviewHoveringView = (<EmailPreviewRow $color={textColor} key={Math.random()} $width={width} $height={30} 
                                        onMouseEnter={() => onHoverEmailPreview(emailIndex)} 
                                        onMouseLeave={() => onMouseOutEmailPreview(emailIndex)}
                                        onClick={() => onPreviewClicked(emailIndex)}> 
                                            <Sender  color={textColor}>{previewData.senderDisplay}</Sender>
                                            <Subject   color={textColor} $width={width - 100 - 100 - 50}>{previewData.subject}</Subject>
                                            <Date  color={textColor}>{previewData.displayDate}</Date>
                                        </EmailPreviewRow>);
        let emailPreviewNotHoveringView = (<EmailPreviewRow $color={textColor} key={Math.random()} $width={width} $height={30} 
                                            onMouseEnter={() => onHoverEmailPreview(emailIndex)} 
                                            onMouseLeave={() => onMouseOutEmailPreview(emailIndex)}
                                            onClick={() => onPreviewClicked(emailIndex)}> 
                                                <Sender  color={textColor}>{previewData.senderDisplay}</Sender>
                                                <Subject  color={textColor}  $width={width - 100 - 100 - 50}>{previewData.subject}</Subject>
                                                <Date color={textColor}>{previewData.displayDate}</Date>
                                        </EmailPreviewRow>);
        return <EmailPreviewRow   $color={textColor} onMouseDown={stopPropagation} onTouchStart={stopPropagation} key={Math.random()} $width={width} $height={30} >
                {
                    activeThreads[emailIndex] ? (emailPreviewHoveringView) :(emailPreviewNotHoveringView)
                }
                </EmailPreviewRow >
          
    }
    function AllEmailsView(threads:any){
        /*
        Given a List of N threads this function returns:
            1. A pretty table of the threads
            2. Some event handlers for each table row               
        */

        // @ts-ignore
        let keys = [...Array(Object.keys(threads).length).keys()];
        return (<InboxList color={textColor} $height={height} $width={width}>
                {keys.map(ind => getEmailRowPreview(getEmailData(threads[ind].messages[0]) , ind) )} 
              </InboxList>);
        
    }

    function GetMessageMainDisplay(messageData: EmailData , content: any){
        /*
        This function is is used to display the old emails in a conversation
        when a user clicks on a conversation in their inbox
        */
        console.log("THREAD ATTACHMENTS: " , threadAttachments);
        let hasAttachment = false;
        if(typeof threadAttachments !== "undefined"){
            if(threadAttachments.hasOwnProperty(messageData.messageId)){
                
                //to do map over each message

                if(threadAttachments[messageData.messageId].length !== 0){
                    hasAttachment = true
                    //<FilePreview key={messageData.messageId + 'attachment'} base64String={threadAttachments[messageData.messageId][0][0]} mimeType={threadAttachments[messageData.messageId][0][1]}/>
                }
                
            }
        }
        return (<EmailMainView key={messageData.messageId + 'mainview'} $backgroundColor={background} $color={textColor} $width={width} $height={height}> 
                                            <Sender >{messageData.senderDisplay}</Sender>
                                            <Date>{messageData.displayDate}</Date>
                                            {content}
                                            {hasAttachment ? threadAttachments[messageData.messageId].map(attachment => <FilePreview base64String={attachment[0]} mimeType={attachment[1]}/>) : ''}
                                            {showMainMessageBox ? <SendMessageBox setShowMessageBox={() => {setShowMainMessageBox(false)}} state={state}secondaryColor={secondaryColor} textColor={textColor} width={width * .7} height={height*.7} backGroundColor={background}/> : ''} 
                </EmailMainView>);
    }
    function GetSingleEmailView(){
        console.log("MAIN VIEW RENDERING")
        let thread = threads[singleEmailToShow];
        // Topic
        // N Messages previews --> onClick they expand
        // Most recent message expanded with reply forward etc
        // all other messages be a preview 
        //thread.messgaes = first .... last email in thread
        return <EmailContainer $color={textColor} $height={height} $width={width}>
                    <BackButton onMouseDown={stopPropagation} onTouchStart={stopPropagation} onClick={() => {setShowSingleEmail(false); setShowMainMessageBox(false);}}>Back</BackButton>
                    <ReplyButton onMouseDown={stopPropagation} onTouchStart={stopPropagation} onClick={() => {setShowMainMessageBox(true)}}>Reply</ReplyButton>
                    {thread.messages.map((message: { payload: { mimeType: string; body: { data: string; }; parts: string | any[]; }; }) => GetMessageMainDisplay(getEmailData(message) ,getMessageContent(message) ) )} 
                
                </EmailContainer>
    }

    

    function MouseOver(event) {
        setHovering(true);
        hovering = true;
        console.log('hovering is true')
    }
    function MouseOut(event){
      setHovering(false);
      hovering = false;
      if(changedProperty){
        let updatedStyle = {bg: background, textColor: textColor}
        updateStyles(state.layout , state.auth , blockId , updatedStyle , saveLayout);
        setChangedProperty(false);
      }
    }


    async function handleSearchInputChange(e){
        //called when the search input bar changes

        //if the user search's for something
        if (e.key === 'Enter' || e.keyCode === 13) {
            //get the search results and then display them
            await getThreads(e.target.value,'none');
            setUpdateMessages(updateThreads += 1);
            console.log('after update: ' , threads)
        }
        else{
            setSearchInput(e.target.value);
        }
        
    }

    async function onMenuEvent(e: { target: { value: any; }; }){
        let eventType = e.target.value;
        if(eventType === 'Inbox'){
            setSearchInput('');
            await getThreads('none','none'); 
            setUpdateMessages(updateThreads += 1);
        }
        else if(eventType === 'Compose'){
            setShowMainMessageBox(true);
            console.log("SHOW MESSAGE COMPOSE!")
        }
        else if(eventType === 'Spam'){
            setSearchInput('');
            await getThreads('none','SPAM'); 
            setUpdateMessages(updateThreads += 1);
        }
        else if(eventType === 'Drafts'){
            setSearchInput('');
            await getThreads('none','DRAFT'); 
            setUpdateMessages(updateThreads += 1);
        }
        else if(eventType === 'Starred'){
            setSearchInput('');
            await getThreads('none','STARRED'); 
            setUpdateMessages(updateThreads += 1);
        }
        else if(eventType === 'Trash'){
            setSearchInput('');
            await getThreads('none','TRASH'); 
            setUpdateMessages(updateThreads += 1);
        }
    }
    console.log('message box',showMainMessageBox)
    return (<div onMouseDown={stopPropagation} onTouchStart={stopPropagation} onMouseOver={MouseOver} onMouseLeave={MouseOut} key='emaildiv'>
            {hovering ? <EmailToolBox onChangedProperty={setChangedProperty} blockId={blockId} onDelete={onDelete} onChangeTextColor={setTextColor} onChangeBackground={setBackground}></EmailToolBox>: ''}
            { showSingleEmail ? GetSingleEmailView() :
                <EmailContainer $color={textColor} $height={height} $width={width} $backgroundColor={background}>
                        <SearchBar backgroundColor={background} fontColor={textColor} onChange={handleSearchInputChange} onKeyDown={handleSearchInputChange}/>
                        <div>
                            <MenuButton onChange={onMenuEvent} color={textColor}>
                                <option disabled selected>Menu ⬇️</option>
                                <option value="Inbox">📨 Inbox</option>
                                <option value="Compose">💌 Compose</option>
                                <option value="Starred">⭐️ Starred</option>
                                <option value="Drafts">📝 Drafts</option>
                                <option value="Spam">🚫 Spam</option>
                                <option value="Trash">🗑️ Trash</option>
                            </MenuButton>
                        </div>
                        
                    {showMainMessageBox ? <SendMessageBox setShowMessageBox={() => {setShowMainMessageBox(false)}} state={state}secondaryColor={secondaryColor} textColor={textColor} width={width * .7} height={height*.7} backGroundColor={background}/> : ''} 
                    { threads.length !== 0  ? ( AllEmailsView(threads)) : ( <p>Loading data...</p> )} 
                </EmailContainer>
            }
            </div> 
        )
    
}