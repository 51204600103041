import NoteFile  from './fileTypes';
import Delta from 'quill-delta';

function isNoteFile(obj){
  return typeof obj === 'object' && obj !== null && typeof obj.name === 'string' && ( obj.html || obj.html === '')
}
function rgbaToRgb(rgba) {
    let toPop = 0;
    // Extract the rgba values using a regular expression
    for (let i = rgba.length - 1; i >= 0; i--) {
      if (rgba[i] === ',') {
          break;
      }
      toPop += 1
    }
    return rgba.slice(0, toPop * (-1)) + '1)';
}
function countStringsInTree(tree) {
  let count = 0;

  function countStrings(item) {
    if (isNoteFile(item)) {
      count += 1;
    } else if (Array.isArray(item)) {
      item.forEach(subItem => countStrings(subItem));
    } else if (typeof item === 'object' && item !== null) {
      count += 1;
      Object.values(item).forEach(value => countStrings(value));
    }
  }

  countStrings(tree);
  return count;
}
  
function flattenFileTree(tree) {
  let result = [];

  function recurse(subtree) {
    if(subtree === undefined){
      return
    }
    //console.log(subtree)
      //console.log(subtree)
      subtree.map(item => {
          if (isNoteFile(item)) {
            result.push(item);
          } else if (typeof item === 'object' && item !== null) {
              // Get the values of the object and recurse
              Object.keys(item).forEach(key => {
                  result.push(key);
                  recurse(item[key]);
              });
          }
      });
  }

  recurse(tree);
  return result;
}
function deepCopy(obj) {
  var rv;
  var toString = Object.prototype.toString;
  switch (typeof obj) {
      case "object":
          if (obj === null) {
              // null => null
              rv = null;
          } else {
              switch (toString.call(obj)) {
                  case "[object Array]":
                      // It's an array, create a new array with
                      // deep copies of the entries
                      rv = obj.map(deepCopy);
                      break;
                  case "[object Date]":
                      // Clone the date
                      rv = new Date(obj);
                      break;
                  case "[object RegExp]":
                      // Clone the RegExp
                      rv = new RegExp(obj);
                      break;
                  // ...probably a few others
                  default:
                      // Some other kind of object, deep-copy its
                      // properties into a new object
                      rv = Object.keys(obj).reduce(function(prev, key) {
                          prev[key] = deepCopy(obj[key]);
                          return prev;
                      }, {});
                      break;
              }
          }
          break;
      default:
          // It's a primitive, copy via assignment
          rv = obj;
          break;
  }
  return rv;
}
function renameFileTree(tree, newName , changeIndex) {
  let renameIndex = 0;  // Keep track of the current position in the rename list

  function recurse(subtree) {
      console.log("SUBTREE:" , subtree)
      subtree.map((item, index) => {
          if (isNoteFile(item)) {
              // Rename the file using the corresponding string from the renameList
              if(renameIndex === changeIndex){
                subtree[index].name = newName
              }
              renameIndex += 1;
          } else if (typeof item === 'object' && item !== null) {
              // Process the folder (object key)
              Object.keys(item).forEach(key => {
                if(renameIndex === changeIndex){
                  item[newName] = item[key];
                  delete item[key];
                  renameIndex += 1
                  return
                }
                else{
                  renameIndex+=1;
                  recurse(item[key]);
                  
                }
              });
          }
      });
  }
  const treeCopy = deepCopy(tree.current);
  console.log("TREE CURRENT IS " , tree.current)
  console.log("TREE COPY IS ", treeCopy)
  recurse(treeCopy);
  console.log(tree.current , treeCopy)
  return treeCopy
}

function getNoteData(tree, noteIndex){
  if(noteIndex === -1){
    return
  }
  console.log('\n\n\n\nin get note data');
  console.log("TREE IS: " , tree);
  let index = 1;  // Keep track of the current position in the rename list
  let html;
  function recurse(subtree) {
      if(subtree === undefined){
        return
      }
      console.log("SUBTREE:" , subtree)
      subtree.map((item, counter) => {
          console.log(isNoteFile(item) ,index , noteIndex )
          if (isNoteFile(item)) {
            console.log('ins note file')
              if(index === noteIndex){
                console.log('returning: ' , item.html)
                html = item.html;
                
              }
              index += 1;
          } else if (typeof item === 'object' && item !== null) {
              // Process the folder (object key)
              console.log('not note file')
              Object.keys(item).forEach(key => {
                if(index === noteIndex){// if user clicked on a folder then there is no html associated with it
                  html = null
                }
                else{
                  index+=1;
                  recurse(item[key]);
                }
              });
          }
      });
  }
  console.log("HTML IS " , html)
  const treeCopy = deepCopy(tree);
  recurse(treeCopy);

  return html.ops ? html.ops : '';
}

function updateFile(tree, noteDeltas , changeIndex) {
  let renameIndex = 1;  // Keep track of the current position in the rename list
  console.log("UPDATE FILE PARAMS" , tree , noteDeltas , changeIndex);
  
  function recurse(subtree) {
      if(subtree === undefined){
        return
      }
      subtree.map((item, index) => {
          console.log("IN subtree.map " , renameIndex , item)
          if (isNoteFile(item)) {
              // Rename the file using the corresponding string from the renameList
              if(renameIndex === changeIndex){
                console.log("RENAMEING TO" , noteDeltas);
                let currentNote = new Delta(subtree[index].html)
                for(let i = 0 ; i<noteDeltas.length;i++){
                  console.log('currentNote: ' , currentNote , typeof currentNote);
                  console.log('notedelta[i]', noteDeltas[i] , typeof noteDeltas);
                  console.log('res',currentNote.compose(noteDeltas[i]) , typeof currentNote.compose(noteDeltas[i]))
                  currentNote = currentNote.compose(noteDeltas[i]);
                  console.log("NOTE AFTER compose via " , noteDeltas[i] , ': ' , currentNote)
                }
                subtree[index].html = currentNote
              }
              renameIndex += 1;
          } else if (typeof item === 'object' && item !== null) {
              // Process the folder (object key)
              Object.keys(item).forEach(key => {
                if(renameIndex === changeIndex){
                  renameIndex += 1
                  return
                }
                else{
                  renameIndex+=1;
                  recurse(item[key]);
                  
                }
              });
          }
      });
  }
  const treeCopy = deepCopy(tree);
  recurse(treeCopy);
  return treeCopy
}


function addFileToTree(tree , fileType, parentIndex) {
  /*
  returns the new tree and the index of the new item
  */

  let count = 0;  // Keep track of the current position in the rename list
  let newFileIndex = 0;
  function recurse(subtree) {
      console.log("SUBTREE IS: " , subtree , typeof subtree)
      subtree.map((item, index) => {
          if (Object.hasOwn(item, 'name') && Object.hasOwn(item, 'html')) {
              console.log('str',count , parentIndex)
              count += 1;
          } else if (typeof item === 'object' && item !== null) {
              // Process the folder (object key)
              console.log('obj',count , parentIndex)
              Object.keys(item).forEach(key => {
                console.log('obj',count , parentIndex)
                if(count === parentIndex){
                  console.log("COUNT IS REACHED!" , fileType)
                  if(fileType === 'file'){
                    item[key].push({'name': 'new file' , 'html':''});
                    newFileIndex = parentIndex + countStringsInTree(item[key]);
                  }
                  else if(fileType === 'folder'){
                    item[key].push({'new folder':[]})
                    newFileIndex = parentIndex + countStringsInTree(item[key]);
                  }
                  count += 1
                  return
                }
                else{
                  count+=1;
                  recurse(item[key]);
                  
                }
              });
          }
      });
  }
  const treeCopy = deepCopy(tree.current);
  recurse(treeCopy);
  console.log('returning: ', newFileIndex)
  return [treeCopy , newFileIndex];
}
export  {rgbaToRgb , countStringsInTree , flattenFileTree , isNoteFile, deepCopy , renameFileTree , addFileToTree , getNoteData , updateFile};